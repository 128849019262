import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import StickyHeader from '../components/stickyHeader';
import { fetchPlatformData } from '../Platforms';
import { Tooltip } from '@mui/material';
import SocialIcons from '../components/SocialIcons';

// Get the shop information
const shop = fetchPlatformData("shop");

const Shop = () => {
  return (
    <Box>
      <StickyHeader label="Our products" />

      {shop.contents.map((category, i) =>

        <Grid key={i} item xs={12} sm={6} md={4} lg={4} >
          <Typography> {category.categoryName + ":"}</Typography>

          {category.products.map((product, j) =>
            <Grid key={j} item xs={12} sm={6} md={4} lg={4} >

              <Card variant='outlined' sx={{ height: "100%" }}>
                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%", justifyContent: "space-between", margin: 2 }}>
                  <img
                    src={product.image}
                    alt=""
                    style={{
                      display: "block",
                      maxHeight: 180
                    }} />

                  <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column", height: "100%", margin: 1 }}>
                    <Typography sx={{ mt: 1 }} gutterBottom variant="subtitle1" component="div"> <b>{product.name}</b> </Typography>
                    <Typography variant="body2" color="text.primary"> {product.description} </Typography>
                    <Typography variant="body2" color="text.primary"> {("Price: "+product.price)} </Typography>


                    <a href={"mailto:" + shop.enquiryEmail + "?subject=" + product.name + "&body=Hi there, I would like to learn more about " + product.name}> <Button variant="contained">Enquire Now</Button> </a>

                  </Box>
                </Box>

              </Card>
            </Grid>)}
        </Grid>
      )}

    </Box>
  )
};

export default Shop;