import * as React from 'react';
import { Button } from '@mui/material';
import { CreateFieldSet } from './Stepper';
import { getFormValues } from '../util/forms';
import { useRef } from 'react';
import { updateOrder } from '../api/rest';
import { CartItems, CartSubTotalsByOrder } from './CartItems';

const displayNormalOrder = (order) => {
    return <div>
        <CartItems aggCartList={order.orderedItems} xs={12} sm={6} md={4} lg={3} />
        <CartSubTotalsByOrder order={order} />
    </div>
}

const DisplayOrder = ({ order, dataList, authorization, wrapAPIAction, }) => {
    const form = useRef(null)
    const readOnly = authorization == undefined || authorization.type != "administrator";

    return <div>
        {order.type === 'normal' && displayNormalOrder(order)}
        {order.type === 'custom' && <form ref={form}><CreateFieldSet step={dataList} i={0} activeStep={0} defaults={order.formData} readOnly={readOnly} /></form>}
        {!readOnly && order.type === 'custom' && <Button sx={{ mt: 2 }} variant="outlined" onClick={async () => {
            const customOrder = getFormValues(form)
            wrapAPIAction(async () => updateOrder({ authorization, orderId: order._id, formValues: { customOrder } }))
        }}>Save Changes</Button>}
    </div>
}

export default DisplayOrder