import * as React from 'react';
import { Alert, AlertTitle, Box, CircularProgress, Container, IconButton, LinearProgress, Stack, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';


const ErrorModal = (props) => {
    return <React.Fragment>
        {props.error && <Box style={{ position: "fixed", top: 74, maxWidth: "1912px", width: "100%", zIndex: 9999999, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Alert sx={{ width: "100%", borderRadius: 0 }} elevation={4} action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        props.setError(null)
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            } severity="error">
                {/* <AlertTitle>Error</AlertTitle> */}
                {props.error}
            </Alert>
        </Box>}
    </React.Fragment>
}

export default ErrorModal