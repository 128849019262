import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, CardHeader, CardMedia, Chip, CircularProgress, Divider, IconButton, Snackbar, Tab, Tabs } from '@mui/material';
import StickyHeader from '../components/stickyHeader';
import Button from '@mui/material/Button';
import { API_ENDPOINT, fetchPlatformData } from '../Platforms';
import { Box } from '@mui/system';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import DefaultMessage from '../components/DefaultMessage';
import DisplayOrder from '../Structures/DisplayOrder';
import OrderAccordion from '../Structures/OrderAccordion';
import TabSet from '../components/Tabs';
import { editUserInformation, getOrdersUsers, getUserInformation, sendMessageUser } from '../api/rest';
import DefaultFormMap from '../components/DefaultFormMap';
import ProgressModal from '../components/ProgressModal';
import { getFormValues } from '../util/forms';
import ShippingTab from '../Structures/ShippingTab';
import BillingTab from '../Structures/BillingTab';
import CustomerTab from '../Structures/CustomerTab';
import StatusTab from '../Structures/StatusTab';
import MenuItem from '@mui/material/MenuItem';
import { stateEnums } from '../Structures/Enums';


function MyAccount() {
    const customerInfoForm = useRef(null);
    const shippingForm = useRef(null);
    const billingForm = useRef(null);

    const [isWaitingAPI, setIsWaitingAPI] = useState(true);
    const [orderHistory, setOrderHistory] = useState(null);
    const [userInfo, setUserInformation] = useState(null);
    const [editInfo, setEditInfo] = useState(false);
    const [moduleRequestsReload, setModuleRequestsReload] = useState(null);

    // Form Data stuff
    let dataList = { ...fetchPlatformData("orderForm")[1], ...fetchPlatformData("orderForm")[2] };

    useEffect(() => {
        setIsWaitingAPI(true)

        const wait = [];
        wait.push(getUserInformation()
            .then((user) => {
                setUserInformation(user)
            }))

        wait.push(getOrdersUsers()
            .then((orders) => {
                setOrderHistory(orders)
            }))

        Promise.all(wait)
            .then(() => {
                setIsWaitingAPI(false)
            })
    }, [])

    useEffect(() => {
        if (moduleRequestsReload === null) return;
        setIsWaitingAPI(true)
        getOrdersUsers()
            .then((res) => {
                setOrderHistory(res)
                setIsWaitingAPI(false)
            })
    }, [moduleRequestsReload])

    const unlockEdit = () => setEditInfo(true);

    const editUserInfo = async () => {
        setIsWaitingAPI(true)

        const userInfo = { ...getFormValues(customerInfoForm), ...getFormValues(shippingForm), ...getFormValues(billingForm) };
        const res = await editUserInformation({ userInfo });

        setUserInformation(res.data.user);
        setEditInfo(false);
        setIsWaitingAPI(false)
    }

    const disabled = !editInfo;

    const customerInfo = userInfo && [
        { name: "phoneNumber", label: "Phone Number", defaultValue: userInfo.phoneNumber, disabled },
        { name: "emailAddress", label: "Email Address", defaultValue: userInfo.emailAddress, disabled }]

    const shippingInfo = userInfo && [
        { name: "shippingAddress", label: 'Shipping Address', defaultValue: userInfo.shipping.address, disabled },
        { name: "shippingSuburb", label: 'Suburb', defaultValue: userInfo.shipping.suburb, disabled },
        { name: 'shippingPostCode', label: "Postcode", defaultValue: userInfo.shipping.postcode, disabled },
        {
            name: 'shippingState', label: "State", defaultValue: userInfo.shipping.state, disabled, options: stateEnums.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                    {option.value}
                </MenuItem>
            ))
        }]

    const billingInfo = userInfo && [
        { name: "billingAddress", label: 'Billing Address', defaultValue: userInfo.billing.address, disabled },
        { name: "billingSuburb", label: 'Suburb', defaultValue: userInfo.billing.suburb, disabled },
        { name: 'billingPostCode', label: "Postcode", defaultValue: userInfo.billing.postcode, disabled },
        {
            name: 'billingState', label: "State", defaultValue: userInfo.billing.state, disabled, options: stateEnums.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                    {option.value}
                </MenuItem>
            ))
        }]

    return <Box style={{ position: 'relative' }}>
        <ProgressModal display={isWaitingAPI} />
        {userInfo && <StickyHeader label={"My Account - " + userInfo.firstName + " " + userInfo.lastName} />}
        {userInfo && <DefaultFormMap items={customerInfo} form={customerInfoForm} />}
        {userInfo && <DefaultFormMap items={shippingInfo} form={shippingForm} />}
        {userInfo && <DefaultFormMap items={billingInfo} form={billingForm} />}

        <Button sx={{ mb: 8 }} variant="outlined" onClick={() => !editInfo ? unlockEdit() : editUserInfo()}>{!editInfo ? "Edit" : "Save"}</Button>

        <StickyHeader label="Order History" />

        {orderHistory && orderHistory.map(order => <OrderAccordion order={order} >
            <TabSet data={[{
                label: "Status",
                content: <StatusTab order={order} customerView={true} />
            }, {
                label: "Messages",
                content: <DefaultMessage
                    messages={order.messages}
                    wrapAPIAction={async (funct) => {
                        setIsWaitingAPI(true);
                        await funct()
                        setIsWaitingAPI(false)
                        setModuleRequestsReload(!moduleRequestsReload)
                    }}
                    sendMessage={(value) => sendMessageUser(order._id, order.orderInfo.customer.firstName + " " + order.orderInfo.customer.lastName, value)} />
            },
            {
                label: "Customer",
                content: <CustomerTab order={order} />
            }, {
                label: "Shipping",
                content: <ShippingTab order={order} />
            }, {
                label: "Billing",
                content: <BillingTab order={order} />
            },
            {
                label: "Order",
                content: <DisplayOrder order={order} dataList={dataList} />
            }]} />
        </OrderAccordion>)}

    </Box>
}

export default MyAccount;