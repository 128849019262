import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CircularProgress, Snackbar, Tabs, Tab } from '@mui/material';
import CustomTabPanel from '../components/CustomTabPanel';


const TabSet = ({ data }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      
    return <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {data.map(item => <Tab label={item.label} />)}
            </Tabs>
        </Box>

        {data.map((item, i) => <CustomTabPanel value={value} index={i}>
            {item.content}
        </CustomTabPanel>)}
    </div>
}

export default TabSet