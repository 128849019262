import * as React from 'react';
import Card from '@mui/material/Card';
import { CardHeader, CardMedia, Step, StepLabel, Typography, Box, Button } from '@mui/material';
import { Stepper as StepperMaterial } from '@mui/material'
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Create a content body for a stepper
const CreateFields = ({ type, items, defaults, readOnly, setParagraphState, setParentError, showUserErrors, paragraphReference }) => {
    const [errors, setErrors] = useState(items.map(i => false));

    const navigate = useNavigate()
    // If the localised error state has been updated, let the parent know that an error now exists
    useEffect(() => {
        const hasError = errors.some(item => item === true)
        setParentError(hasError)
    }, [errors])

    // Check whether there are any initial errors in the default values
    // Ie. uninitialised or otherwise incorrect data
    useEffect(() => {
        // TODO: let paragraph state also use an array function instead of an object
        let paragraph = {}

        const initialErrors = items.map(item => {
            const elementName = item.id || item.name
            const defaultValue = defaults?.[elementName] || ""

            // Do paragraph tracking
            if (item.trackState) paragraph[elementName] = defaultValue

            // If item contains error function
            if (!item.isError) return false;

            const thisError = item.isError(defaultValue)
            return thisError;
        })


        // If there are initial errors let this know locally and the parent as well
        setErrors(initialErrors)
        setParagraphState(paragraph)
    }, [])

    const m = 0.5

    if (type === "cards") return <Grid container spacing={2} marginLeft={0} marginRight={0} >
        {items.map((item, i) => (<Grid key={i} xs={12} sm={6}>
            <Button variant="outlined" onClick={() => navigate(item.link)}>
                <CardHeader title={item.name} />
                <br />
                <CardContent>{item.content}</CardContent>
            </Button>
        </Grid>
        ))}
    </Grid>



    return <React.Fragment>
        {items.map((item, i) => {
            // Whether to observe changes
            const elementName = item.id || item.name
            const isError = errors[i];

            const onChange = (e) => {
                if (item.trackState) setParagraphState({ [item.id]: e.target.value })
                if (item.isError) {
                    const thisIsError = item.isError(e.target.value);
                    if (errors[i] !== thisIsError) {
                        errors[i] = thisIsError;
                        setErrors([...errors])
                    }
                }
            }

            let display = "flex";
            if (item.visibleIf?.(paragraphReference) === false) display = "none";

            if (item.type === "custom") return item.content

            if (item.type === "booleanDropDown") item.items = ["Yes", "No"]

            if (item.type === "image") return <Card variant="outlined">
                <CardMedia sx={{ padding: 0, margin: 0 }} loading="lazy">
                    <img style={{ ...item.style, ...{ display } }} src={item.imgsrc} />
                </CardMedia>
            </Card>

            if (item.type === "rawimage") return <div>
                <img style={{ ...item.style, ...{ display } }} src={item.imgsrc} />
            </div>


            return <TextField
                name={elementName}
                helperText={(isError && showUserErrors) ? item.helperText : ""}
                sx={{ flexGrow: 1, display: "flex", m }}
                label={item.name}
                variant="outlined"
                defaultValue={defaults?.[item.id]}
                disabled={readOnly}
                select={item.type === "dropDown" || item.type === "booleanDropDown"}
                error={isError && showUserErrors}
                autoComplete="off"
                onChange={onChange}>
                {item.items?.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>

        })}
    </React.Fragment>
}

// Create a step for a stepper
const CreateFieldSet = ({ step, i, activeStep, defaults, readOnly, setParentError, showUserErrors }) => {
    const [paragraphState, setParagraphState] = useState(step.paragraphs.map(i => { }))
    // Track the error state for each paragraph
    const [errors, setErrors] = useState(step.paragraphs.map(i => false))
    // This is needed to support forms that bypass the stepper, ie. createfieldset is directly invoked
    const [bypassedShowUserError, setBypassedShowUserError] = useState(false)

    useEffect(() => {
        if (setParentError) setParentError(errors.some(error => error === true))
        if (!setParentError) setBypassedShowUserError(true)
    }, [errors])

    const paragraphReference = {}
    paragraphState.forEach(para => Object.assign(paragraphReference, para))

    console.log(paragraphState)
    if (step.type === "custom") return step.content(i, activeStep);

    // Check whether this item should be mounted
    // This is to populate in default values which can only occur once per mount
    if (step.mounted === null) return;

    return step.paragraphs.map((paragraph, j) => {
        // Whether this should be displayed in the case it has dependencies on other values
        let display = "block"
        if (activeStep !== i) display = "none"
        if (paragraph.visibleIf && !paragraph.visibleIf(paragraphReference)) return null

        const style = paragraph.style || { display: "flex", flexDirection: "row", flexWrap: "wrap" }

        return (<div key={j} style={{ display }}>
            <Typography variant="subtitle1" sx={{ ml: 1, mb: 1, mt: 1 }}>{paragraph.name}</Typography>

            <div style={style}>
                <CreateFields type={paragraph.type} items={paragraph.items} defaults={defaults} readOnly={readOnly} setParagraphState={(paragraph) => {
                    paragraphState[j] = paragraph
                    setParagraphState([...paragraphState])
                }} paragraphState={paragraphState} setParentError={(isError) => {
                    errors[j] = isError;
                    setErrors([...errors])
                }} showUserErrors={bypassedShowUserError || showUserErrors}
                    paragraphReference={paragraphReference}
                />
            </div>
        </div>)
    })
}


const Stepper = ({ steps, defaults }) => {

    const [activeStep, setActiveStep] = useState(0)
    // Whether there are errors on this page
    // We need to keep track of them for whether the user can navigate next
    const [errors, setErrors] = useState(steps.map(step => false));
    const [showUserErrors, setShowUserErrors] = useState(steps.map(step => false));

    useEffect(() => { }, [])

    const handleNext = () => {
        // Check if there are any errors on the active step
        if (errors[activeStep]) {
            // If so make the errors visible to the user
            showUserErrors[activeStep] = true
            setShowUserErrors([...showUserErrors])
            return
        }

        const comingStep = activeStep + 1
        setActiveStep(comingStep)
        steps[comingStep]?.onActive?.()
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    };

    const handleSkip = () => {
    };

    function handleReset() {
    }

    const isLast = steps.length - 1 === activeStep;

    const nextDisabled = isLast || steps[activeStep].nextDisabled
    const backDisabled = activeStep === 0

    return <React.Fragment>
        <StepperMaterial activeStep={activeStep} style={{ margin: 0 }}>
            {steps.map((label, index) => {
                label = label.name
                const stepProps = {};
                const labelProps = {};
                // if (isStepOptional(index)) {
                //     labelProps.optional = (
                //         <Typography variant="caption">Optional</Typography>
                //     );
                // }
                // if (isStepSkipped(index)) {
                //     stepProps.completed = false;
                // }
                return (
                    <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
            })}
        </StepperMaterial>

        <Box mb={2} />

        {/* Completion message */}
        {steps.map((step, i) =>
            <Box sx={{ display: i === activeStep ? "inline" : "none" }}>
                {(step.content && step.type === "custom") ? step.content() : <CreateFieldSet step={step} i={i} activeStep={activeStep} defaults={defaults}
                    setParentError={(hasError) => {
                        errors[i] = hasError
                        setErrors([...errors])
                    }} showUserErrors={showUserErrors[i]} />}
            </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

            <Button
                color="inherit"
                disabled={backDisabled}
                onClick={handleBack}
                sx={{ mr: 1 }}
                disableElevation
                variant='outlined'>
                Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />


            {/* Next button */}
            {!nextDisabled && <Button variant='outlined' disableElevation onClick={() => handleNext()}>
                {steps[activeStep].customNext ? steps[activeStep].customNext : 'Next'}
            </Button>}

        </Box>
    </React.Fragment>
}

export {
    CreateFields,
    CreateFieldSet,
    Stepper
}