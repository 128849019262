import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { getFormValues } from '../util/forms';
import { useRef } from 'react';
import { updateOrder } from '../api/rest';
import DefaultFormMap from '../components/DefaultFormMap';
import { orderStatusEnums, paymentStatusEnums, shippingCarrierEnums } from './Enums';


const StatusTab = ({ authorization, order, wrapAPIAction, customerView }) => {
    const form = useRef(null)
    
    const disabled = customerView || authorization == undefined || authorization.type != 'administrator';
    const staffAllowed = !customerView || authorization != undefined && (authorization.type === 'staff' || authorization === 'administrator');

    const items = [{
        name: "orderStatus", label: "Order Status", defaultValue: order.status, options:
            orderStatusEnums.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                    {option.value}
                </MenuItem>
            ))
        , disabled: staffAllowed ? false : true
    },
    {
        name: "paymentStatus", label: "Payment Status", defaultValue: order.orderInfo.payment.status, options: paymentStatusEnums.map((option) => (
            <MenuItem key={option.key} value={option.value}>
                {option.value}
            </MenuItem>
        )), disabled
    },
    { name: "orderSubTotal", label: "Order SubTotal", defaultValue: order.subtotal, disabled },
    { name: "shippingTotal", label: "Shipping Total", defaultValue: order.shipping, disabled },
    { name: "myobOrderNum", label: "MYOB Order Number", defaultValue: order.myobOrderNum, disabled },
    {
        name: "shippingCarrier", label: "Shipping Carrier", defaultValue: order.orderInfo.delivery.type, options: shippingCarrierEnums.map((option) => (
            <MenuItem key={option.key} value={option.value}>
                {option.value}
            </MenuItem>
        )), disabled: staffAllowed ? false : true
    },
    { name: "trackingNumber", label: "Tracking Number", defaultValue: order.orderInfo.delivery.tracking, disabled: staffAllowed ? false : true }]

    // Do some modifications for customer view
    if (customerView) {
        // Remove these admin fields
        delete items[4];
    }

    return <Box>
        <DefaultFormMap form={form} items={items} />
        {!disabled && <Button sx={{ mt: 1 }} variant="outlined" onClick={async () => {
            wrapAPIAction(async () => updateOrder({ authorization, orderId: order._id, formValues: getFormValues(form) }))
        }}>Save Changes</Button>}
    </Box>
}

export default StatusTab