
const VPWCategories = {
    "Brake System": {
        "Brake Boosters": {
            "11\"": {},
            "7\"": {},
            "8\"": {},
            "9\"": {}
        },
        "Brake Cables": {},
        "Brake Hoses": {},
        "Brake Hub Assemblies": {},
        "Brake Kits": {
            "Brake Kit Hardware": {},
            "Carbon Rear Brake Kits": {},
            "Classic Series Dynalite": {}
        },
        "Brake Line": {
            "ADR Compliant Brake Line": {}
        },
        "Brake Pads": {},
        "Calipers": {
            "Caliper Bolts & Pins": {},
            "Caliper Kits with Pads": {},
            "Caliper Replacement Parts": {},
            "Calipers & Rebuild Kits": {},
            "D52 Dual Piston Calipers": {},
            "Dynalite Series": {},
            "Dynapro Dust-Boot Calipers": {},
            "Dynapro Radial Mount Calipers": {},
            "Forged Dynalite Calipers": {},
            "Narrow Dynalite": {},
            "Narrow Dynapro Lug Mount Calipers": {},
            "Replacement Components": {},
            "TX6R Forged Radial Mount": {}
        },
        "Hydraulic Handbrake": {},
        "Line Locks": {
            "Line Lock Accessories": {},
            "Universal Burnout Kit": {}
        },
        "Master Cylinders": {
            "Aluminimum Tandem Master Cylinder": {},
            "Combination Remote Master Cylinder": {},
            "Compact Remote Master Cylinders": {},
            "GS Compact Remote Master Cylinders": {},
            "High Volume Master Cylinder": {},
            "Kart Master Cylinder": {},
            "Master Cylinders & Rebuild Kits": {},
            "Proflow Master Cylinders": {},
            "Remote Master Cylinder Reservioirs": {},
            "Slave Cylinder": {},
            "Tandem Master Cylinder": {}
        },
        "Pedals": {
            "Balance Bar Replacement": {},
            "Clutch Pedal Kit": {},
            "Floor Mount Pedal Assembly": {},
            "Rev Swing Mount Triple MC w/ Balance Bar": {}
        },
        "Proportioning & Pressure Valves": {
            "Accessories": {},
            "Brake Proportioning Valves": {},
            "Residual Pressure Valve": {}
        },
        "Rotors": {
            "Rotor Adapters": {},
            "Rotor Bolts": {},
            "Rotor Hats": {}
        },
        "Vacuum Pumps & Reservoirs": {
            "Electric Vacuum Pump Kit": {},
            "Vacuum Canister": {},
            "Vacuum Pump Replacement Components": {},
            "Vacuum Reservoir Tank": {}
        }
    },
    "Chassis & Fabrication": {
        "Chassis": {
            "Bonnet Hinge Kits": {},
            "Boot Hinge Kits": {},
            "Bump Steer Kits and Components": {},
            "Driveshaft Loops": {},
            "Engine Mounting": {},
            "Frame Rails": {},
            "K-Member & Support Brace": {},
            "Strut Tower Bracing": {},
            "Transmission Mounting": {}
        },
        "Fabrication": {
            "Brackets & Tabs": {},
            "Fabricated Components": {},
            "Gaffers Tape": {},
            "Weld Bungs & Caps": {}
        }
    },
    "Consumables": {
        "Adhesive": {},
        "Automotive Grease": {},
        "Cleaners & Degreasers": {},
        "Fluids": {
            "Brake Fluid": {},
            "Hydraulic Fluid": {},
            "Power Steering Fluid": {},
            "Suspension Fluids": {},
            "Transmission Fluids": {},
            "Treatment Fluids": {}
        },
        "Marking Compound": {},
        "Oil & Additives": {
            "Engine Oil": {},
            "Engine Oil Additives": {},
            "Fuel Treatments": {},
            "Gear Oil": {},
            "Problem Solvers & Utility Lubricants": {}
        },
        "Race Fuel": {
            "E85": {},
            "Methanol": {},
            "Unleaded": {}
        },
        "Sealants": {},
        "Thread Locker": {},
        "Valve / Lapping Compound": {},
        "Vehicle Detailing": {
            "Wash, Wax & Polish": {},
            "Wheel, Tyre & Trim": {},
            "Interior Cleaning": {},
            "Glass Cleaning": {}
        }
    },
    "Cooling & Air Conditioning": {
        "Air Conditioning": {
            "Air Condition Compressors": {},
            "A/C Compressor Conversion Kits": {},
            "A/C Brackets": {},
            "Air Conditioning Systems": {},
            "Heater Components": {}
        },
        "Appearance": {
            "Washer Tanks": {}
        },
        "Coolent Filters": {},
        "Fluid Coolers & Kits": {},
        "Fans & Components": {
            "Fans & Kits": {},
            "Fan Controls": {},
            "Fan Clutches": {},
            "Mounting Hardware": {}
        },
        "Overflow and Recovery Tanks": {},
        "Inline Temp Sensor Adapters": {},
        "Radiators": {
            "Vehicle Specific": {},
            "Universal": {},
            "Radiator Caps": {},
            "Radiator Filler Necks": {},
            "Radiator Overflow Tanks": {},
            "Radiator Hose Kits": {}
        },
        "Thermostats": {
            "High Performance Thermostats": {},
            "Thermostat Housings": {}
        },
        "Valley Coolant Plates": {},
        "Water Hose": {},
        "Water Pumps": {
            "Electric": {},
            "Mechanical": {},
            "Water Pump Parts": {}
        }
    },
    "Electrical": {
        "Alternators & Replacement Parts": {
            "Ford": {},
            "GM": {},
            "Replacement Parts": {},
            "Universal": {}
        },
        "Batteries & Chargers": {
            "Battery Accessories": {},
            "D Series Audio Batteries": {}
        },
        "Crank Triggers": {},
        "Distributors": {
            "Distributor Parts": {},
            "Distibutors": {}
        },
        "Distributors & Ignition Kits": {},
        "Electrical Terminals & Accessories": {},
        "Ignition": {
            "Accessories": {},
            "Ignition Coil Accessories": {},
            "Ignition Coil Brackets": {},
            "Ignition Coils": {},
            "Ignition Leads": {}
        },
        "Magneto Generators": {},
        "RPM / Timing Controls": {
            "Modules": {},
            "RPM Controls": {},
            "Timing Controls": {}
        },
        "Sensors": {},
        "Spark Plugs & Accessories": {
            "Spark Plug Indexing Washers": {}
        },
        "Starter Motors & Accessories": {
            "Chevrolet": {},
            "Chrysler": {},
            "Clutch Assemblies & Repair Kits": {},
            "Ford": {},
            "GM LS": {},
            "Holden": {},
            "Starter Rebuild Parts": {},
            "Starter Solenoids": {}
        },
        "Steering Column Electrical Components": {},
        "Switch Panels & Accessories": {
            "Rocker Switch Panels": {},
            "Toggle Switch Panels": {}
        },
        "Voltage Boosters": {}
    },
    "Engines & Components": {
        "Components": {
            "Accessory Drives & Pulleys": {},
            "Alternator Brackets": {},
            "Balancers & Dampers": {},
            "Block-Off Plates": {},
            "Blocks": {},
            "Camshafts": {},
            "Car Engine Lifters": {},
            "Car Engine Valves": {},
            "Connecting Rods": {},
            "Crankshafts": {},
            "Cylinder Heads": {},
            "Engine Bearings": {},
            "Engine Dress-Up Kits": {},
            "Engine Finishing": {},
            "Main Caps & Girdles": {},
            "Piston Locks": {},
            "Pistons": {},
            "Pushrods & Guide Plates": {},
            "Rocker Arms": {},
            "Rotating Assemblies": {},
            "Timing Gears": {},
            "Top End Kits": {},
            "Valley Covers & Baffles": {},
            "Valve Covers & Accessories": {},
            "Valve Springs & Components": {}
        },
        "Crate Engines": {
            "Chevrolet": {},
            "Chrysler": {},
            "Ford": {},
            "GM LS": {}
        },
        "Gaskets": {
            "Air Cleaner Gaskets": {},
            "Carburettor Gaskets": {},
            "Cylinder Head Gaskets": {},
            "Differential Housing Gaskets": {},
            "Exhaust Gaskets": {},
            "Gasket Kits": {},
            "Gasket Sheets": {},
            "Intake Manifold Gaskets": {},
            "O-Rings": {},
            "Oil Pan Gaskets": {},
            "Rear Main Seals": {},
            "Throttle Body Gasket": {},
            "Timing Cover Gaskets": {},
            "Transmission Gaskets": {},
            "Turbocharger Gaskets": {},
            "Valve Cover Gaskets": {},
            "Water Pump Gaskets": {}
        },
    },
    "Exhaust & Insulation": {
        "Cat Back Systems": {},
        "Catalytic Converters": {},
        "Crankcase Evacuation Systems": {},
        "Exhaust Accessories": {
            "Electric Exhaust Cut Outs": {},
            "Exhaust Hangers": {},
            "Hanger Rod": {},
            "Header Gasket": {}
        },
        "Exhaust Systems": {},
        "Exhaust Piping": {
            "Down Pipes": {},
            "Stainless Steel Piping": {},
            "Steel Piping": {},
            "Titanium Tubing": {},
            "X & H Piping": {},
            "Y Pipe": {}
        },
        "Headers": {
            "Chevrolet Small-Block": {},
            "Ford XR to XF Falcon": {},
            "GM LS": {},
            "Holden Commodore": {},
            "Holden HQ To WB": {},
            "Holden LH LX Torana & HK-HG": {}
        },
        "Header Flanges": {},
        "Insulation": {
            "Acoustic Shielding": {},
            "Clamps & Ties": {},
            "Distributor Heat Wrap": {},
            "Exhaust Wrap & Shields": {},
            "Heat Shield Kits": {},
            "Heat Shield Mats & Sheets": {},
            "Heat Shield Sleeves": {},
            "Heat Sheld Tape": {},
            "Spark Plug Boots": {},
            "Starter Motor Heat Shields": {},
            "Turbo Beanie & Blankets": {}
        },
        "Mufflers & Resonators": {
            "Mufflers": {},
            "Resonators": {}
        },
        "Stainless Steel Exhaust Flanges": {},
        "Turbo Manifolds": {
            "Audi": {},
            "Ford": {},
            "Izusu": {},
            "Mitsubishi": {},
            "Nissan": {},
            "Toyota": {},
            "Volkswagen": {}
        },


    },
    "Exterior": {
        "Accessories": {
            "Fuel Doors": {}
        },
        "Bonnet": {},
        "Bumpers": {
            "Bumper Guards": {},
            "Bumpers": {},
            "Hardware & Brackets": {}
        },
        "Convertible Tops & Components": {
            "Components": {},
            "Convertible Top": {}
        },
        "Door": {
            "Car Door Handles": {},
            "Hinges": {},
            "Locks": {},
            "Mirrors": {}
        },
        "Exterior Lights": {
            "Fog Lights": {},
            "Headlights": {},
            "Indicators": {},
            "Parking Lights": {},
            "Tail Lights": {}
        },
        "Firewall, Cowl & Front Unibody": {},
        "Fender & Radiator Supports": {
            "Car Fenders": {},
            "Fender Aprons": {},
            "Splash Shields": {}
        },
        "Floor Pan & Frame": {},
        "Graphuic Kits": {},
        "Grilles": {},
        "Quarter Panels": {},
        "Rocker Panels": {},
        "Roof Panels & Components": {},
        "Spoilers": {},
        "Trim & Mouldings": {
            "Decals": {},
            "Door Trim": {},
            "Emblem": {},
            "Fender Trim": {},
            "Quarter Panel Trims": {},
            "Rocker Panel": {},
            "Trunk & Taillights": {}
        },
        "Valances": {},
        "Weatherstrip": {},
        "Window & Windshield": {
            "Door Glass & Components": {},
            "Quarter Glass & Components": {},
            "Sunshades": {},
            "Vent Windows": {},
            "Window Louvers": {},
            "Windshields & Trim": {},
            "Wiper Washer Components": {}
        }
    },
    "Fasteners": {
        "Clamps": {
            "Hose Clamps": {},
            "Hose Finishers": {},
            "P-Clamps": {},
            "Pipe Couplings": {},
            "Tubing Clamps": {},
            "Universal Clamps": {},
            "V-Band": {}
        },
        "Driveline Fasteners": {
            "Auto / Manual Transmission Bolts": {},
            "Axle Studs": {},
            "Bellhousing Bolts & Studs": {},
            "Brake Bolts": {},
            "Carrier Bolts & Studs": {},
            "Clutch Cover / Pressure Plate Bolts High Performance": {},
            "Drive Plate Bolt Kits": {},
            "Flexplate Bolts": {},
            "Flywheel Bolts": {},
            "Rear End Cover Bolts": {},
            "Ring Gear Bolts": {},
            "Torque Converter Bolts": {},
            "Water Pump & Lower Pulley Bolts": {},
            "Wheel Fasteners": {}
        },
        "Fasteners": {
            "Accessory Stud Kits": {},
            "Bulk Fasteners": {},
            "Cylinder Head Fasteners": {},
            "Engine Block Fasteners": {},
            "Engine Component Fasteners": {},
            "Head Bolt bushing Kits": {},
            "Intake System Fasteners": {},
            "Quick Release Fasteners": {},
            "Rod Bolt Fasteners": {}
        }
    },
    "Fittings & Adapters": {
        "Adapters": {
            "Barb Adapters": {},
            "Breather Adapters": {},
            "Bulkhead Adapters": {},
            "Carburettor & Fuel Line Fittings": {},
            "Distribution Adapters": {},
            "Expanders, Reducers & Extensions": {},
            "Female Unions": {},
            "Female/Male Unions": {},
            "Fuel Cell Fittings": {},
            "Heater Core Adapters": {},
            "Male Unions": {},
            "Other": {},
            "Steam Tube Adpaters": {},
            "Tee Adaptors": {},
            "Thermostat Housing Adapters": {},
            "Turbo Oil Drain Adapters": {}
        },
        "Air Conditioning Fittings": {},
        "Brake System Fittings": {
            "Banjo Fittings": {},
            "Brake Locator Fittings": {},
            "Crimp Fittings": {},
            "Inverted Flare Fittings": {},
            "NPT Fittings": {},
            "Other": {}
        },
        "Caps & Plugs": {},
        "Flow Control Valves": {},
        "Hose End Fittings": {
            "100 Series Hose Ends": {},
            "200 Series PTFE Hose Ends": {},
            "400 Series Push Lock Hose Ends": {},
            "500 Series Cutter Style Hose Ends": {},
            "510 Series Full Flow Push Lock Hose Ends": {},
            "570 Series Full Flow Forged PTFE Hose Ends": {},
            "600 Series Crimp Hose Ends": {},
            "Hose End Bulk Packs": {},
            "Threaded Hose Ends": {}
        },
        "Quick Connect Fittings": {
            "Female Quick Connect Tee": {},
            "Female Quick Connect Tee To Male NPT": {},
            "Female Quick Connect To Barb": {},
            "Female Quick Connect to Barb 90 Degree": {},
        },
        "Replacement Parts": {},
        "Transmission Fittings": {},
        "Tube Fittings": {},
        "Weld On Fittings": {},
        "Water Pump Fittings": {}
    },
    "Forced Induction": {
        "Blow Off Valves": {
            "Big Bubba": {},
            "Blow-Off Valve Accessories": {},
            "Kompact Series Dual Port": {},
            "Kompact Series Plumb Back": {},
            "Race Port": {},
            "TMS Valves": {},
            "Type 5 Dual Port": {},
            "Type 5 Plumb Back": {},
            "Type 5 Supersonic": {},
            "Vee Port Pro": {}
        },
        "Boost Controllers": {
            "Electronic Boost Controllers": {},
            "Manual Boost Controllers": {},
        },
        "Intercoolers": {
            "Air to Air": {},
            "Air to Ice": {},
            "Air to Liquid": {},
            "EcoBoost Intercooler": {},
            "Intercooler Cores": {},
            "Intercooler Kits": {},
            "Intercooler Pipe Kits": {},
            "Universal Z-Line Intercoolers": {}
        },
        "Nitrous Components & Accessories": {
            "Accessory Packs": {},
            "Battery Mount": {},
            "Blow Down Tubes": {},
            "Bottle Brackets": {},
            "Bottle Heater": {},
            "Bottle Valves": {},
            "Burst Accessories": {},
            "Distribution Blocks": {},
            "Flow Checking Systems": {},
            "Hoses": {},
            "Injector Plates": {},
            "Jets": {},
            "Nitrous Bottle Accessories": {},
            "Nitrous Bottles": {},
            "Nitrous Controllers & Accessories": {},
            "Nitrous Filters": {},
            "Nozzles & Accessories": {},
            "Purge Systems": {},
            "Refill Components": {},
            "Solenoids & Service Parts": {},
            "Stainless Steel Tubing": {},
            "Switches": {},
            "Transducers": {}
        },
        "Nitrous Systems": {
            "Carbureted Plate Systems": {},
            "Diesel System": {},
            "Direct Port Systems": {},
            "Ecoboost Plate System": {},
            "EFI Systems": {},
            "Hidden Nitrous Systems": {},
            "Nozzle System": {},
            "Scion/Subaru Plate Systems": {},
        },
        "Superchargers & Accessories": {
            "Accessories": {},
            "Superchargers": {}
        },
        "Turbochargers & Accessories": {
            "Oil & Water Lines": {},
            "Turbine Housings": {},
            "Turbo Bellmouths": {},
            "Turbo Inlet Flanges": {},
            "Turbo Mesh Screen": {},
            "Turbochargers": {}
        },
        "Wastegates & Accessories": {
            "Accessories": {},
            "Wastegates": {}
        }
    },
    "Fuel & Air System": {
        "Air Filters": {
            "Oval Air Cleaners": {},
            "Parts": {},
            "Pod Filter": {},
            "Round Air Cleaners": {},
            "Trianglar Air Cleaners": {}
        },
        "Air Intakes": {
            "Intake Elbows": {}
        },
        "Carburettor & Manifold Kits": {},
        "Carburettors & Components": {
            "Accelerator Pump Parts": {},
            "Adaptors & Spacers": {},
            "Adjustable Fuel Logs": {},
            "Carburettor Accessories": {},
            "Carburettors": {},
            "Choke Components": {},
            "Floats": {},
            "Fuel Bowls & Parts": {},
            "Fuel Lines": {},
            "Jets & Bleeds": {},
            "Main Body Upgrades": {},
            "Metering Block Conversion Kits": {},
            "Needle & Seats": {},
            "Power Valves": {},
            "Tuning & Assortment Kits": {},
            "Vacuum Secondary Parts": {},
            "Venturi Booster Kits": {}
        },
        "EFI Systems": {
            "Atomic EFI": {},
            "EZ-EFI": {},
            "Fuel Injection Plate System": {},
            "Sniper EFI": {}
        },
        "Fuel Filters & Elements": {
            "Fuel Filters": {},
            "Replacement Elements": {}
        },
        "Fuel Injectors": {
            "Audi/VW": {},
            "BMW": {},
            "Ford": {},
            "GM": {},
            "Honda": {},
            "Lexus": {},
            "Lotus": {},
            "Mazda": {},
            "Mercedes-Benz": {},
            "Mitsubishi": {},
            "Nissan": {},
            "Porsche": {},
            "Subaru": {},
            "Toyota": {},
            "Universal": {}
        },
        "Fuel Pressure Regulators": {
            "Carburetted FPR": {},
            "Fuel Injected (EFI) FPR": {},
            "Fuel Pressure Regulator Brackets": {},
            "Replacement Diaphragms": {}
        },
        "Fuel Pumps": {
            "Accessories": {},
            "Carburetted Electric": {},
            "Carburetted Mechanical": {},
            "EFI Electric": {},
            "Fuel Pump & Filter Brackets": {},
            "Fuel Pump Pushrods": {},
            "Install Kits": {},
            "Rebuild Components": {}
        },
        "Fuel Pump Carriers": {},
        "Fuel Rail & Accessories": {
            "Adaptors & Accessories": {},
            "Chevrolet Big-Block": {},
            "Chevrolet Small Block": {},
            "Ford 5L": {},
            "Ford BA BF Falcon 6 Cylinder": {},
            "Ford FG Falcon 6 Cylinder Turbo": {},
            "Ford Windsor 302": {},
            "Fuel Rail Extrusions": {},
            "GM LS": {},
            "Holden 304 308 V8 EFI": {},
            "Mazda Rotary Series 4/5": {},
            "Mazda Rotary Series 6": {},
            "Nissan RB25": {},
            "Nissan RB26": {},
            "Nissan SR20": {},
            "Nissan TB48": {},
            "Nissan/Holden RB30": {},
            "Toyota 2JZ": {}
        },
        "Fuel Tanks & Accessories": {
            "Accessories": {},
            "Pontiac": {},
            "Universal": {}
        },
        "Intake Manifolds": {
            "Chevrolet Big-Block": {},
            "Chevrolet Small-Block": {},
            "Chrysler Big-Block": {},
            "Chrysler HEMI": {},
            "Chrysler Small-Block": {},
            "Ford 4.6L & 5.0L & 5.8L": {},
            "Ford 6 Cylinder": {},
            "Ford Big-Block": {},
            "Ford Windsor": {},
            "GM LS": {},
            "Holden": {},
            "Intake Manifold Spacers": {},
            "Mounting Kits": {},
            "Nissan": {},
            "Oldsmobile": {},
            "Toyota": {}
        },
        "Scoops": {
            "Dual Carburettors": {},
            "Scoop Plugs": {},
            "Single Carburettors": {}
        },
        "Surge Tanks": {
            "Ford": {},
            "Universal": {}
        },
        "Throttle Bodies & Components": {
            "4150 & 4500 Bolt Pattern": {},
            "Chrysler": {},
            "Ford": {},
            "GM LS": {},
            "Idle Motors": {},
            "Mounting Accessories": {},
            "Throttle Cable Brackets & Linkages": {},
            "Throttle Cables": {},
            "Universal": {}
        },
        "Water-Methanol Injection": {
            "Accessories": {},
            "Diesel": {},
            "Petrol / Gasoline": {}
        }
    },
    "Gauges & Compoents": {
        "Accessories": {
            "Gauge Hose Kits": {},
            "Lens Kits": {},
            "Other": {},
            "Pressure Sensors": {},
            "Temperature Sensors": {}
        },
        "Mounting": {
            "2\" Hole Gauge Panels": {},
            "2\" Mounting Cups": {},
            "2-5/8\" Hole Gauge Panels": {},
            "2-5/8\" LFG Mounting Cups": {},
            "2-5/8\" Mounting Cups": {},
            "3-3/8\" Monster GA Mounting Cups": {},
            "Angle Rings": {},
            "Carbon Fibre Mounting Cups": {},
            "Dash Inserts": {},
            "Tachometer Shock Strap Kit": {}
        },
        "Shift & Warning Lights": {
            "12 LED Super-Lite Shift Lights": {},
            "DPSS Tube Level 1": {},
            "DPSS Tube Level 2": {},
            "DPSS Tube Level 3": {},
            "Mini Pro-Lite Cover Kit": {},
            "Mini Pro-Lite Warning Lights": {},
            "Pro-Lite Warning Light": {},
            "Quick-Lite Shift Lights": {},
            "Shift Lights": {},
            "Sniper Standalone Shift Lights": {}
        },
        "Universal Gauges": {
            "Air/Fuel Ratio": {},
            "Boost/Vacuum": {},
            "Climate Control Panels": {},
            "Clocks": {},
            "Cooling Fan Controller": {},
            "Cruise Control Kits": {},
            "Fuel Level": {},
            "Gauge Sets": {},
            "Gear Position": {},
            "Pressure Gauges": {},
            "Shift Light Gauge": {},
            "Speedometer": {},
            "Tachometer": {},
            "Temperature Gauges": {},
            "Voltage & Amps": {}
        },
        "Vehicle Specific Gauges": {
            "Buick": {},
            "Cadillac": {},
            "Chevrolet": {},
            "Dodge": {},
            "Ford": {},
            "Jeep": {},
            "Lincoln": {},
            "Mopar": {},
            "Oldsmobile": {},
            "Pontiac": {},
            "Toyota": {}
        }
    },
    "Hoses & Tubing": {
        "Aluminimum Fuel Line": {},
        "Aluminimum Tubing": {
            "Straight": {},
            "30 Degree": {},
            "60 Degree": {},
            "90 Degree": {},
            "135 Degree": {},
            "180 Degree": {},
            "Other": {}
        },
        "Braideed Hose": {
            "Black Braided Hose": {},
            "Inner Support Spring": {},
            "Stainless Braided Hose": {}
        },
        "Brake Tube": {},
        "Carbon Fibre Tubing": {},
        "Cast Aluminimum Piping": {},
        "Flexible Ducting": {},
        "Nylon Tubing": {},
        "Rubber Hose": {},
        "Submersible Fuel Hose": {},
        "Silicone Hose & Hardware": {
            "Straight Hose": {},
            "45 Degree Hose": {},
            "90 Degree Hose": {},
            "135 Degree Hose": {},
            "180 Degree Hose": {},
            "Straight Reducers": {},
            "45 Degree Reducers": {},
            "90 Degree Reducers": {},
            "Hardware": {},
            "Heater Hose": {},
            "Radiator Hose": {},
            "Silicone Hump Hose": {},
            "Vacuum Hose": {}
        },
        "Stainelss Tubing": {
            "Straight": {},
            "30 Degree": {},
            "45 Degree": {},
            "60 Degree": {},
            "90 Degree": {},
            "135 Degree": {},
            "180 Degree": {},
            "Pie Cuts": {}
        },
        "Titanium Tubing": {
            "Straight": {},
            "45 Degree": {},
            "90 Degree": {},
            "180 Degree": {},
            "U-J Bend": {},
            "Pie Cuts": {}
        },
        "Transparent Hose": {},
    },
    "Interior Parts": {
        "Accessories & Components": {
            "Ash Trays": {},
            "Door Components": {},
            "Rear View Mirrors": {},
            "Sun Visors": {},
            "Window Cranks": {}
        },
        "Audio Components": {
            "Bezels": {},
            "Other": {},
            "Speakers": {}
        },
        "Boot": {},
        "Console": {
            "Arm Rests": {}
        },
        "Dash": {
            "Components": {},
            "Dash Pads": {},
            "Glove Box": {},
            "Instrument Cluster & Panels": {}
        },
        "Flooring": {
            "Car Carpets": {},
            "Floor Mats": {}
        },
        "Headliner": {
            "Windlace": {}
        },
        "Interior Lights": {},
        "Seats & Components": {
            "Seat Components": {},
            "Seatbelts & Hardware": {},
            "Upholstery": {}
        },
        "Trim": {
            "Quarter Panels": {}
        }
    },
    "Oil System": {
        "Catch Cans & Breather Tanks": {
            "Plastic Breather Tanks": {}
        },
        "Dipsticks": {
            "Engine Dipsticks": {},
            "Transmission Dipsticks": {}
        },
        "Filter Mounts & Adaptors": {},
        "Oil Coolers": {},
        "Oil Filters": {},
        "Oil Pans": {
            "Chevrolet Big-Block": {},
            "Chevrolet Small-Block": {},
            "Chrysler 318 340 360": {},
            "Ford Big-Block": {},
            "Ford Cleveland": {},
            "Ford Windsor": {},
            "GM LS": {},
            "Holden": {},
            "Other": {}
        },
        "Oil Pressure Regulator": {},
        "Oil Pumps & Pickups": {
            "Chevrolet Big-Block Oil Pump & Pickup Assemblies": {},
            "Chevrolet Small-Block Oil Pump & Pickup Assemblies": {},
            "Chevrolet Small-Block Oil Pumps": {},
            "Chrysler Big-Block & Hemi Oil Pumps": {},
            "High Volume Oil Pumps": {}
        },
        "Oil System Accessories": {
            "Crankshaft Wipers": {},
            "Gauge Hose Kits": {},
            "Oil Pan Drain Plug": {},
            "Oil Pump Pickup": {},
            "Oil Pump Screen Assemblies": {},
            "Oil Pump Shafts": {},
            "Oil Restrictors": {},
            "Other": {},
            "Windage Trays": {}
        },
        "Vacuum Pumps & Accessories": {
            "Vacuum Pump Dry Sump Pump Drive Kits": {},
            "Vacuum Pump Mounting Brackets": {}
        }
    },
    "Powertrain Management": {
        "Data Loggers & Displays": {},
        "ECU's": {},
        "Fuel Cut Defenders": {},
        "Modules": {},
        "Other": {},
        "Plugs & Connectors": {
            "Coil Connectors": {},
            "Idle Motor Connectors": {},
            "Injector Connectors": {},
            "Other": {},
            "Oxygen Sensor Connectors": {},
            "Position Sensor Connectors": {},
            "Pressure Sensor Connectors": {},
            "Temperature Sensor Connectors": {}
        },
        "Sensor Adapters": {},
        "Sensors & Transducers": {
            "Fuel/Oxygen Sensors": {},
            "Other": {},
            "Position": {},
            "Pressure": {},
            "Temperature": {}
        },
        "Wiring Harnesses": {}
    },
    "Safety Equipment": {
        "Harnesses & Restraints": {
            "Latch & Link Harnesses": {},
            "Camlock Harnesses": {},
            "Torso Racing Harnesses": {},
            "Lap Belts": {},
            "Harness Mounting Hardware & Accessories": {},
            "Arm Restraints": {},
            "Window Nets": {}
        },
        "Helments": {
            "Helmets": {},
            "Carbon Fibre Helmets": {},
            "Snell Approved Helmets": {},
            "Motorcycle Helmets": {},
            "Crew Helmets": {},
            "Helmet Accessories": {},
            "HANS, Restraints & Hybrids": {}
        },
        "Interior Padding": {
            "Roll Bar Padding": {},
            "Roll Bar Padding (SFI)": {}
        },
        "Parachutes & Accessories": {},
        "Racing Seats & Hardware": {
            "Racing Seats & Covers": {},
            "Seat Supports & Covers": {},
            "Seat Mounts": {}
        },
        "Racewear": {
            "Racing Suits": {},
            "Undergarments": {},
            "Racing Gloves": {},
            "Footwear": {},
            "Gear Bags": {}
        },
        "Tie-Downs & Accessories": {}
    },
    "Steering & Suspension": {
        "Steeering Components": {
            "Idler Arms": {},
            "Pitman Arms": {},
            "Power Steering Tanks & Brackets": {},
            "Steering Column Accessories": {},
            "Steering Columns": {},
            "Steering Couplers": {},
            "Steering Pumps & Brackets": {},
            "Steering Racks & Boxes": {},
            "Steering Shafts": {},
            "Steering Wheel Horn Buttons": {},
            "Steering Wheel Install Hubs": {},
            "Steering Wheels": {},
            "Tie Rod Ends": {},
            "Universal Joints": {}
        },
        "Suspension": {
            "4-Links Kits": {},
            "Ball Joints": {},
            "Bushings": {},
            "Caster Camber Plates": {},
            "Clevises": {},
            "Ladder Bars": {},
            "Other": {},
            "Rear Lowering Block": {},
            "Rod Ends & Related": {},
            "Shackles": {},
            "Shock Absorbers": {},
            "Springs": {},
            "Strut Packages": {},
            "Stub Axles/Spindles": {},
            "Suspension Arms & Link Components": {},
            "Suspension Handling Packages": {},
            "Sway Bars & End Links": {},
            "Tie Rod Sleeves": {},
            "Traction Devices": {},
            "Whiteline": {}
        }
    },
    "Tools & Equipment": {
        "Car Covers": {},
        "CO2 Bottle Kits": {},
        "Tools": {
            "Carburetor Tools": {},
            "Differential Tools": {},
            "Electrical Wire Tools": {},
            "Engine Building Tools": {},
            "Hose & Pipe Tools": {},
            "Measuring Tools": {},
            "Oil System Tools & Trays": {},
            "Quick Fastener Tools": {},
            "Spanners & Wrenches": {},
            "Timing & Ignition Tools": {}
        },
        "Vehicle Ramps": {
            "Car Service Ramps": {},
            "Trailer Loading Ramps": {}
        },
        "Weather Stations": {
            "Cables": {},
            "Racing Weather Stations": {}
        },
        "Workshop Equipment": {
            "Air Tanks": {},
            "Calculators": {},
            "Cylinder Head Stands": {},
            "Engine & Thread Cleaning": {},
            "Engine Balancing Scales": {},
            "Engine Lift Plates": {},
            "Engine Stands": {},
            "Fender Covers": {},
            "Floor Mats": {},
            "Jugs & Funnels": {},
            "Piston Ring Filers": {},
            "Portable Steps": {},
            "Pressure Gauges": {},
            "Safety Wire & Pliers": {},
            "Storage Cases": {},
            "Straight Edge": {},
            "Thread Identification": {},
            "Transmission Stands & Tools": {},
            "Trays": {},
            "Tyre Covers": {},
            "Vehicle Scales": {},
            "Vise Jaws": {}
        }
    },
    "Transmission & Drivetrain": {
        "Drivetrain": {
            "Axles": {},
            "Cases": {},
            "Center Sections": {},
            "Complete Rear End Assemblies": {},
            "Couplings & Sleeves": {},
            "Differential": {},
            "Differential Gear Sets": {},
            "Driveshafts": {},
            "Floater Kits & Components": {},
            "Housings & Components": {},
            "Installation Parts": {},
            "Main Caps & Covers": {},
            "Pinion Supports": {},
            "Retainer Plates": {},
            "Universal Joints & Girdles": {},
            "Yokes": {}
        },
        "Transmssion": {
            "Clutches": {},
            "Flexplates": {},
            "Flywheels": {},
            "Gear Shifting Cables": {},
            "Housings & Cases": {},
            "Shifters & Parts": {},
            "Torque Converters & Accessories": {},
            "Transmission Catch Cans": {},
            "Transmission Pans": {},
            "Transmission Parts": {},
            "Transmissions": {},
            "Valve Bodies": {}
        }
    },
    "Wheels & Tyres": {
        "Tyres": {
            "Drag Racing Slicks": {},
            "Street": {},
            "Street/Strip": {}
        },
        "Wheels": {
            "Drag Racing": {},
            "Racing": {},
            "Street": {},
            "Street/Strip": {}
        },
        "Wheel Accessories": {
            "Centre Caps": {},
            "Locking Lug Nuts": {},
            "Spacers": {},
            "Beadlock Rings": {},
            "Bead-Loc Bolt Kit": {},
            "Plugs": {},
            "Tube": {},
            "Wheel Simulators": {},
            "Wheel Bearing & Seal Kits": {}
        }
    }
};

const RocketCategories = {
    "Air Filtration": {
        "Air Filter Assemblies": {
            "Oval": {},
            "Round": {},
            "Single & Two Barrel": {},
            "Stacks & Air Boxes": {},
            "Triangular": {},
            "Weber": {}
        },
        "Air Filters": {
            "Cabin Filters": {},
            "Motorcycle & ATV Filters": {},
            "Panel Filters": {},
            "Pod Filters": {},
            "Replacement Elements": {},
            "Round Filters": {}
        },
        "Air Filter Accessories": {
            "Bases & Lids (Tops)": {},
            "Filter Cleaning": {},
            "Spacers, Adapters & Gaskets": {},
            "Studs & Nuts": {},
            "Wraps": {}
        },
        "Air Scoops & Plenums": {},
        "Breathers & Adapters": {
            "Breathers": {},
            "Breather Adapters & Grommets": {},
            "Oil Fill / Breather": {}
        },
        "Intake Systems": {}
    },
    "Apparel & Promotional": {
        "Apparel": {
            "Aprons": {},
            "Caps / Hats": {},
            "Hoodies": {},
            "Jackets & Jumpers": {},
            "Singlets": {},
            "T-Shirts": {}
        },
        "Memorabilia, Novelties & Gifts": {
            "Antenna Toppers": {},
            "Books": {},
            "Floor Mats": {},
            "Gift Cards": {},
            "Key Rings": {},
            "Licence / Number Plates": {},
            "Revolving Wall Lights": {},
            "Signs": {},
            "Stickers, Tags & Patches": {}
        },
        "Promotional": {
            "Banners": {},
            "Counter Displays": {},
            "Ear Plugs": {},
            "Stubby Coolers": {},
            "Catalogues": {}
        }
    },
    "Body, Exterior & Interior Accessories": {
        "Bodies": {
            "1932 Ford 5-Window Coupe": {},
            "1932 Ford Roadster": {},
            "1932-1934 Ford Closed Cab Pickup": {}
        },
        "Body Panels": {
            "Bonnets / Hoods": {},
            "Boots / Trunk Lids": {},
            "Braces & Hardware": {},
            "Cowl Vent & Spears": {},
            "Fire Walls": {},
            "Floor Panels": {},
            "Front Aprons": {},
            "Fuel Doors": {},
            "Guards / Fenders": {},
            "Patch Panels": {},
            "Running Boards": {}
        },
        "Bonnets / Hoods": {
            "Bonnet & Boot Props": {},
            "Bonnet Adjusters": {},
            "Bonnet Braces": {},
            "Bonnet Handles": {},
            "Bonnet Hardware": {},
            "Bonnet Latches": {},
            "Bonnet Mouldings": {},
            "Bonnet Ornaments": {},
            "Bonnet Pins": {},
            "Bonnet Release": {}
        },
        "Bumpers": {
            "Bumper Hardware & Accessories": {},
            "Bumpers": {}
        },
        "Exterior": {
            "Door Handles": {},
            "Emblems": {},
            "Mirrors": {},
            "Windscreen Frames": {},
            "Windscreen Hardware": {},
            "Windscreen Stanchions": {},
            "Wiper Accessories": {},
            "Wiper Blades": {},
            "Washer Bottles": {},
            "Wiper Motors & Kits": {}
        },
        "Grilles": {
            "Grille Inserts": {},
            "Grille Mouldings": {},
            "Grille Shells": {},
            "Grill to Radiator Brackets": {}
        },
        "Hinges & Latches": {
            "Bonnet Hinges": {},
            "Boot / Trunk Hinges": {},
            "Door Hinges": {},
            "Dovetails": {},
            "Hinge Pins": {},
            "Latches": {},
            "Strikers": {}
        },
        "Interior": {
            "Accelerator Pedals": {},
            "Brake and Clutch Pedals & Pads": {},
            "Cup Holders": {},
            "Dashes": {},
            "Dimmer Pads": {},
            "Door Accessories": {},
            "Door Handles": {},
            "Door Lock Knobs": {},
            "Door Pulls": {},
            "Lights": {},
            "Mirrors": {},
            "Roll Bar Protectors": {},
            "Seat Covers": {},
            "Seat Rails, Mounts & Hardware": {},
            "Seats": {},
            "Trims": {},
            "Window Cranks": {}
        },
        "Rear Spoiler / Wing": {},
        "Rubbers & Lacing": {
            "Lacing": {},
            "Pads": {},
            "Seals": {},
            "Window Channels": {}
        }
    },
    "Brake": {
        "Brake Accessories": {
            "Bleed Nipples": {},
            "Brake Bias Kits": {},
            "Brake Fluid": {},
            "Line Lockers": {},
            "Pressure Gauges": {},
            "Switches": {},
            "Vacuum Pumps": {},
            "Vacuum Tanks": {}
        },
        "Brake Boosters": {
            "Boosters": {},
            "Extension Rods & Clevis": {},
            "Mounting Brackets": {}
        },
        "Brake Kits": {
            "Front Disc Brake Kits": {},
            "Rear Disc Brake Kits": {},
            "Rear Drum Brake Kits": {}
        },
        "Brake Pads": {},
        "Calipers": {
            "Calipers": {},
            "Caliper Components": {}
        },
        "Disc Rotors": {
            "Disc Rotors": {},
            "Rotor Components": {}
        },
        "Drum Brake Components & Accessories": {
            "Springs & Clips": {},
            "Wheel Cylinders": {}
        },
        "Hand Brake": {
            "Brackets, Clevis & Switches": {},
            "Cables": {},
            "Hand Brake Kits": {},
            "Hand Brake Boots": {},
            "Levers": {}
        },
        "Hose & Fittings": {
            "Banjos": {},
            "Brackets, Clamps & Clips": {},
            "Brake Line Kits": {},
            "Bulkheads": {},
            "Couplers": {},
            "Fittings & Adapters": {},
            "Hardline": {},
            "Hose Ends, Olives & Sockets": {},
            "PVC Coated Stainless Steel Braided Hose": {},
            "Quick Release Fittings": {},
            "Stainless Steel Braided Hose": {},
            "Tee's": {},
            "Through Frame Fittings": {},
            "Tube Sleeves & Nuts": {},
            "Unions": {},
            "Washers": {}
        },
        "Master Cylinders": {
            "Conventional": {},
            "Remote": {},
            "Reservoirs": {},
            "Adapters, Brackets, Eyelets & Mounts": {}
        },
        "Pedal Assemblies & Components": {
            "Adapter Kits": {},
            "Pedal Arms": {},
            "Pedal Assemblies": {},
            "Pedal Pads": {}
        },
        "Tools": {
            "Bleeder Kit": {},
            "Flaring Tools": {},
            "Tube Bender": {}
        },
        "Valves": {
            "Booster Valves": {},
            "Proportioning Valves": {},
            "Residual Valves": {},
            "Shut Off Valves": {}
        }


    },
    "Chassis, Suspension & Steering": {
        "Chassis": {
            "Chassis Clamps": {},
            "Control Levers": {},
            "Cross Members": {},
            "Engine Mounts & Adapters": {},
            "Engine Plates": {},
            "Frame Rails": {},
            "Hinge Brackets": {},
            "Limiter Kits": {},
            "Parachute Mounts": {},
            "Pedals & Stops": {},
            "Radiator Mounts": {},
            "Spreader Bars": {},
            "Tailshaft Loops": {},
            "Transmission Mounts": {},
            "Weld-On Tabs": {},
            "Wheelie Bars": {},
            "Wing Struts": {}
        },
        "Suspension": {
            "4-Links": {},
            "Air Bag Suspension": {},
            "Ball Joints": {},
            "Bump Stops": {},
            "Bushings": {},
            "Caltracs": {},
            "Complete Front Suspension": {},
            "Drag Links & Tie Rods": {},
            "Front Axles": {},
            "Front Spindles": {},
            "Front Struts": {},
            "King Pins": {},
            "Leaf Springs": {},
            "Lowering Blocks": {},
            "Radius Rods & Torsion Bars": {},
            "Rear Axle Locators": {},
            "Shackles & Spring Hangers": {},
            "Shock Mounts & Brackets": {},
            "Shocks & Springs": {},
            "Spring Perches": {},
            "Sway Bars": {},
            "U-Bolts": {}
        },
        "Steering": {
            "Pitman Arms": {},
            "Power Steering Fittings & Valves": {},
            "Power Steering Pumps & Pulleys": {},
            "Power Steering Tanks & Reservoirs": {},
            "Rod Ends": {},
            "Steering Arms": {},
            "Steering Boxes": {},
            "Steering Columns & Mounts": {},
            "Steering Dampers": {},
            "Steering Racks": {},
            "Steering Shafts": {},
            "Steering Wheels & Hubs": {},
            "Steering Wheel Accessories": {},
            "Universal Joints": {}
        }
    },
    "Cooling, Insulation & Air Conditioning": {
        "Cooling": {
            "Electric Fans": {},
            "Electric Fan Mounts": {},
            "Electric Fan Switches, Controllers & Relays": {},
            "Engine Heaters & Accessories": {},
            "Fan Shrouds": {},
            "Fittings & Adapters": {},
            "Heat Exchangers": {},
            "Hose Ends & Finishers": {},
            "Mechanical Fans": {},
            "Overflow & Expansion Tanks": {},
            "Radiator Caps & Covers": {},
            "Radiator Hoses & Adapters": {},
            "Radiators": {},
            "Restrictor Plates": {},
            "Switches & Relays": {},
            "Thermostat Housings": {},
            "Thermostats": {},
            "Water Cross Overs & Logs": {},
            "Water Pumps": {},
            "Water Pump Adapters": {},
            "Water Pump Fittings": {},
            "Water Pump Spacers & Shims": {},
            "Zinc Anode Plugs": {},
            "T-Pieces": {}
        },
        "Insulation": {
            "Brake & Clutch Heat Blankets": {},
            "Exhaust Wraps": {},
            "Flex Braid Wrap": {},
            "Fuel Jug Covers": {},
            "Heat Barriers": {},
            "Heat Coating & Adhesive": {},
            "Heat Shields": {},
            "Heat Sleeves": {},
            "Noise & Heat Insulation": {},
            "Spark Plug Heat Boots": {},
            "Turbo Blankets": {},
            "Wastegate Blankets": {},
            "Wire & Hose Overwrap": {},
            "Fuel Injector & Sensor Heat Blankets": {}
        },
        "Air Conditioning & Heating": {
            "A/C Kits": {},
            "Bulkheads": {},
            "Compressors & Bracket": {},
            "Condensers": {},
            "Controller Panels": {},
            "Driers": {},
            "Ducting": {},
            "Evaporators": {},
            "Fittings & Adapters": {},
            "Heaters": {},
            "Hose": {},
            "Louvers & Vents": {},
            "Olives, Valves & O-Rings": {},
            "Switches": {},
            "Assorted A/C Parts": {}
        }
    },
    "Electrical": {
        "Alternators & Starter Motors": {
            "Alternators": {},
            "Alternator Brackets & Mounts": {},
            "Alternator Replacement Parts": {},
            "Starter Motors": {},
            "Starter Motor Replacement Parts": {}
        },
        "Batteries & Trays": {
            "Batteries": {},
            "Battery Boxes": {},
            "Battery Disconnect Switches": {},
            "Battery Trays": {},
            "Hold Down Clamps": {},
            "Terminals": {}
        },
        "Connectors, Bulkheads, Terminals & Heat Shrink": {},
        "Electrical": {
            "Door Actuators & Solenoids": {},
            "Door Contacts": {},
            "Power Windows": {},
            "Racing communications": {}
        },
        "Electric Fans": {
            "Electric Fans": {},
            "Electric Fan Mounts": {},
            "Electric Fan Switches, Controllers & Relays": {}
        },
        "Electronic Throttle Control": {
            "Throttle Control Units": {},
            "Throttle Control Wiring Harnesses": {}
        },
        "Fuses, Switches & Relays": {
            "Fuses & Circuit Breakers": {},
            "Relays": {},
            "Switch Panels": {},
            "Switches": {}
        },
        "Gauges & Dashes": {
            "Dashes & Data Loggers": {},
            "Gauges": {},
            "Gauge Kits": {},
            "Gauge Adapters": {},
            "Gauge Cups, Panels & Pods": {},
            "Gauge Line Kits": {},
            "Sender & Sensors": {},
            "Shift & Warning Lights": {},
            "Shift Indicators": {},
            "Speedometers": {},
            "Tachometers": {}
        },
        "Ignition": {
            "Crank Triggers": {},
            "Distributors": {},
            "Distributor Caps & Rotors": {},
            "Distributor Gears": {},
            "Distributor Parts": {},
            "Electronic Conversion Kits": {},
            "Electronic Timing & RPM Controls": {},
            "Firewall Feed Thru": {},
            "Ignition Coils & Mounts": {},
            "Ignition Controls": {},
            "Ignition Leads": {},
            "Ignition Lead Boots & Terminals": {},
            "Ignition Lead Separators": {},
            "Power Grids": {},
            "Pro-Mag Magnetos": {},
            "Resistors": {},
            "Spark Plugs": {},
            "Tach & Fuel Adapters": {},
            "Tools": {},
            "Vacuum Advance": {}
        },
        "Lighting": {
            "Brake & Tail Lights / Lamps": {},
            "Dash Lights / Lamps": {},
            "Head Lights / Lamps": {},
            "Number Plate": {},
            "Turn Signals": {}
        },
        "Remote / Keyless": {
            "Keyless Entry": {},
            "Keyless Ignition": {}
        },
        "Sensors": {
            "Map Sensors": {},
            "Oxygen Sensors": {},
            "Pressure Sensors": {},
            "Sensor Delete Plugs & Plates": {},
            "Knock Sensors": {}
        },
        "Tuning": {
            "Air/Fuel Ratio": {},
            "Data Loggers": {},
            "Engine Management": {}
        },
        "Wiring Looms, Harnesses, Plugs & Pins": {
            "Coil Harness & Plugs": {},
            "Injector Loom Adapters": {},
            "Injector Plugs": {},
            "Plugs & Pins": {},
            "Wiring Harnesses / Looms": {}
        }
    },
    "Engine & Components": {
        "Crate Engines": {
            "Long Engines": {},
            "Short Engines": {},
        },
        "Engine Dress-Up": {
            "Alternator Brackets": {},
            "Belt Tensioners": {},
            "Block Off Plates / Covers": {},
            "Breathers": {},
            "Breather / Oil Fill": {},
            "Coil Covers": {},
            "Dipsticks": {},
            "Distributor Hold Down Clamps": {},
            "Engine Dress-Up Kits": {},
            "Gilmer Drives & Belts": {},
            "Oil Caps": {},
            "PCV Valves": {},
            "Power Steering Brackets": {},
            "Pulleys & Spacers": {},
            "Side Plates": {},
            "Valley Covers": {},
            "Valve Covers": {}
        },
        "Engine Components": {
            "Balancers": {},
            "Bearings": {},
            "Belt Drives": {},
            "Blocks": {},
            "Cam Buttons": {},
            "Cam Gears": {},
            "Camshafts": {},
            "Conrods": {},
            "Crank Hubs": {},
            "Crankshafts": {},
            "Crankshaft Keys": {},
            "Cylinder Heads": {},
            "Engine Dowels": {},
            "Engine Finishing Kits": {},
            "Gaskets & Seals": {},
            "Gear Drives": {},
            "Intake Manifolds": {},
            "Lifters": {},
            "Main Caps": {},
            "Manifold Spacers": {},
            "Oil Gallery Plugs": {},
            "Pistons": {},
            "Piston Rings & Oil Supports": {},
            "Piston Pins & Circlips": {},
            "Pushrods & Guide Plates": {},
            "Rear Cam Plugs": {},
            "Rear Seal Adapters": {},
            "Reluctor Wheels": {},
            "Rockers": {},
            "Rocker Components": {},
            "Rocker Studs & Nuts": {},
            "Rotating Assemblies": {},
            "Sleeves": {},
            "Stud Girdles": {},
            "Thrust / Retaining Plates": {},
            "Timing Belts": {},
            "Timing Covers & Pointers": {},
            "Timing Chains": {},
            "Valves": {},
            "Valve Lash Caps": {},
            "Valve Springs, Retainers & Locks": {},
            "Valve Spring Locators & Cups": {},
            "Water Pumps": {},
            "Welch Plugs": {},
            "Cam Sync Plugs": {}
        },
    },
    "Exhaust": {
        "Catalytic Converters": {},
        "Cut-Outs & Control Valves": {},
        "Exhaust Fabrication": {
            "2 & 3 Bolt Flanges": {},
            "Donuts": {},
            "Flexible Joints": {},
            "Header Flanges": {},
            "Pie Cuts": {},
            "Straight Pipe & Mandrel Bends": {},
            "Transition Cones": {},
            "Turbo Flanges": {},
            "Turnouts / Turndowns": {},
            "V-Band Clamps": {},
            "X-Pipe, Y-Pipe & T-Pipe": {},
            "Steam Pipe": {}
        },
        "Exhaust Gaskets": {},
        "Exhaust Hangers": {},
        "Exhaust Systems": {},
        "Exhaust Tips": {},
        "Headers & Manifolds": {
            "Block Huggers": {},
            "Full Length Headers": {},
            "Merge Collectors": {},
            "Rams Horns": {},
            "Shorty Headers": {},
            "Side/Lake Pipes": {},
            "Turbo Headers & Manifolds": {},
            "Weld-Up Kits": {}
        },
        "Mufflers": {
            "Smithy's": {},
            "Stainless Steel": {},
            "Steel": {}
        }
    },
    "Fabrication & Fasteners": {
        "Assorted Fasteners": {
            "12-Point Nuts": {},
            "Accessory Studs": {},
            "Acorn Nuts": {},
            "Body Bolts": {},
            "Brake Hat Bolts": {},
            "Cleco Fasteners": {},
            "Dress Up Bolts": {},
            "Fender / Guard Bolts": {},
            "Hex Nuts": {},
            "Nyloc Nuts": {},
            "Quick Release Pins": {},
            "Rivets": {},
            "Screw Packs": {},
            "Serrated Flange Nuts": {},
            "Titanium Bolts, Washers & Nuts": {},
            "Universal Bolt Sets": {},
            "Windscreen Fasteners": {},
            "Spade Bolts": {}
        },
        "Dzus Fasteners": {
            "Buttons": {},
            "Mounting Brackets": {},
            "Reinforcing Plates": {},
            "Self-Ejecting": {},
            "Springs": {}
        },
        "Engine Fasteners": {
            "Air Filter Studs": {},
            "Alternator Bolts & Studs": {},
            "Balancer Bolts": {},
            "Blower Pulley Bolts": {},
            "Blower Studs": {},
            "Cam Bolts": {},
            "Carburettor Studs": {},
            "Coil & Bracket Bolts": {},
            "Conrod Bolts": {},
            "Distributor Studs": {},
            "Engine & Accessory Bolt Kits": {},
            "Engine Mount Bolts": {},
            "Flexplate Bolts": {},
            "Flywheel Bolts": {},
            "Fuel Pump Bolts": {},
            "Head Bolts": {},
            "Head Studs": {},
            "Header Bolts & Studs": {},
            "Individual Head & Main Studs": {},
            "Intake Manifold Bolts & Studs": {},
            "Main Bolts": {},
            "Main Studs": {},
            "Oil Pan / Sump Bolts & Studs": {},
            "Oil Pump Bolts & Studs": {},
            "Pulley Bolts & Studs": {},
            "Rear Cover Bolts": {},
            "Starter Motor Bolts": {},
            "Thermostat Housing Bolts": {},
            "Timing Cover Bolts & Studs": {},
            "Valve Cover Bolts & Studs": {},
            "Water Pump Bolts": {},
            "Valley Cover Bolts & Studs": {}
        },
        "Fabrication": {
            "Bolt Spacers": {},
            "Donuts": {},
            "Drain Valves": {},
            "Hex Spacers": {},
            "Pie Cuts": {},
            "Rod Ends & Clevis": {},
            "Straight Pipe & Mandrel Bends": {},
            "Tank Ends": {},
            "Transition Cones": {},
            "Weld In Nozzle Fittings": {},
            "Weld In Plates": {},
            "Weld On 02 Sensor Bung & Plugs": {},
            "Weld On AN Fittings": {},
            "Weld On Diff Filler": {},
            "Weld On EGT Bung & Caps": {},
            "Weld On Filler & Caps": {},
            "Weld On Filler Necks": {},
            "Weld On Injector Bungs": {},
            "Weld On Metric Fittings": {},
            "Weld On NPT Fittings": {},
            "Weld On Radiator Necks & Fittings": {},
            "Weld On ORB Fittings": {},
            "Weld In Tube Adapters": {},
            "X-Pipe, Y-Pipe & T-Pipe": {},
            "Steam Pipe": {}
        },
        "Fastener Tools": {
            "Assembly Lube": {},
            "Dzus Wrenches": {},
            "Thread Cleaning Chasers": {},
            "Thread Sealers": {}
        },
        "Transmssion & Driveline Fasteners": {
            "Bellhousing Bolts & Studs": {},
            "Bellhousing Dowels": {},
            "Carrier Bolts & Studs": {},
            "Pressure Plate Bolts": {},
            "Ring Gear Bolts": {},
            "Transmission Pan Bolts": {},
            "Torque Converter Bolts & Nuts": {}
        },
        "Tyre & Wheel Fasteners": {
            "Speed Nuts": {},
            "Wheel Nuts": {},
            "Wheel Studs": {}
        },
        "Washers & Grommets": {
            "Grommets": {},
            "Safety Washes": {},
            "Sealing Washers": {},
            "Special Purpose Washers": {},
            "Washers": {}
        }
    },
    "Fuel System": {
        "Carburettors": {
            "Accelerator Pump Components": {},
            "Assorted Carburettor Parts": {},
            "Carburettors - Mechanical": {},
            "Carburettors - Progressive": {},
            "Carburettors - Vacuum": {},
            "Carburettors - Stromberg": {},
            "Choke Kits": {},
            "Fittings & Adapters": {},
            "Floats": {},
            "Fuel Bowls": {},
            "Fuel Lines / Rails": {},
            "Gaskets": {},
            "Jets": {},
            "Linkages, Levers & Balls": {},
            "Needle & Seats": {},
            "Power Valves": {},
            "Rebuild Kits": {},
            "Spacers & Adapters": {},
            "Stromberg Carburettor Parts": {}
        },
        "Check & Shut-Off Valves": {
            "Check Valves": {},
            "Shut-Off Valves": {}
        },
        "Electronic Injection": {
            "ECU & Harnesses": {},
            "EFI Fuel Systems": {},
            "Fittings & Adapters": {},
            "Fuel Injector Bungs & Adapters": {},
            "Fuel Injectors & Clips": {},
            "Fuel Injector Service Kits": {},
            "Fuel Rails, Mounts & Adapters": {},
            "Regulator Adapters": {},
            "Sensors & Switches": {},
            "Throttle Bodies, Adapters & Brackets": {}
        },
        "Fuel Blocks, Logs & Y-Blocks": {
            "Fuel Blocks": {},
            "Fuel Logs": {},
            "Y-Blocks": {}
        },
        "Fuel Filters": {
            "Filter Brackets": {},
            "Fuel Filters": {},
            "Replacement Elements": {}
        },
        "Fuel Jugs & Funnels": {
            "Fuel Jugs": {},
            "Funnels": {}
        },
        "Fuel Pressure Gauges": {},
        "Fuel Pressure Regulators": {
            "Carburettor Regulators": {},
            "EFI Regulators": {}
        },
        "Fuel Pumps": {
            "Fuel Pumps - Belt Drive": {},
            "Fuel Pumps - Electric": {},
            "Fuel Pumps - Hex Drive": {},
            "Fuel Pumps - Mechanical": {},
            "Fuel Pump Brackets": {},
            "Fuel Pump Fittings & Adapters": {},
            "Fuel Pump Pushrods": {},
            "Fuel Pump Modules": {},
            "Fuel Pump Parts & Accessories": {}
        },
        "Fuel Tanks & Cells": {
            "Fuel Cell Bulkheads & Fittings": {},
            "Fuel Cell Foam": {},
            "Fuel Cell Mountings": {},
            "Fuel Cells": {},
            "Fuel Cooler Tanks": {},
            "Fuel Filler Caps & Fillers": {},
            "Fuel Sender Units": {},
            "Fuel Tank Accessories": {},
            "Fuel Tanks": {},
            "Hydramat": {},
            "Pump Hangers": {},
            "Roll Over Valves / Vents": {},
            "Spill Trays": {}
        },
        "Linkages & Rods": {},
        "Mechanical Injection": {
            "Blower Injector Hats": {},
            "Check Valves": {},
            "Distribution Blocks": {},
            "Fuel Pump Belt Drives": {},
            "Fuel Pumps": {},
            "Hard Lines": {},
            "Hex Drives": {},
            "High Speed Accessories": {},
            "Hoses": {},
            "Jets, Nozzels & Pills": {},
            "Linkage Components": {},
            "Metering / Barrel Valves": {},
            "Shims & Springs": {},
            "Shut-Offs": {},
            "Tunnel Ram Injectors": {}
        },
        "Morse Cables & Accessories": {},
        "Return Springs & Assemblies": {},
        "Surge Tanks": {},
        "Throttle & Kickdown Cables": {
            "Kickdown Cables & Brackets": {},
            "Throttle Cables & Brackets": {}
        },
    },
    "Fuels, Lubricants, Paint, Chemicals & Car Care": {
        "Care Care": {
            "Dusters & Towels": {},
            "Oil & Wax Removers": {},
            "Paint Protection": {},
            "Tar & Rubber Remover": {},
            "Wash, Wax & Polish": {}
        },
        "Chemicals": {
            "Block Filler": {},
            "Degreaser": {},
            "Electrcial / Contact Cleaner": {},
            "Engine Cleaners": {}
        },
        "Fluids": {
            "Air Tool Oil": {},
            "Brake Fluid": {},
            "Coolants": {},
            "Power Steering Fluid": {},
            "Suspension Fluids": {},
            "Supercharger & Compressor Oil": {}
        },
        "Fuels & Additives": {
            "Fuel Additives": {},
            "Methanol": {},
            "Syphon Pumps": {},
            "Unleaded Fuels": {}
        },
        "Lubricants & Sealers": {
            "Anti-Seize": {},
            "Assembly Lube": {},
            "Break-In Oils": {},
            "Cam & Lifter Lube": {},
            "Chain Lube": {},
            "Grease": {},
            "Oil Stabiliser": {},
            "Sealants": {},
            "Thread Sealers": {},
            "Upper Lube": {}
        },
        "Oils": {
            "Castor Oils": {},
            "Diesel Oils": {},
            "Engine Oils": {},
            "Gear / Diff Oils": {},
            "Motorcycle & Kart Oils": {},
            "Transmission Oils": {}
        },
        "Paints": {
            "Brake Caliper, Drum & Rotor Paints": {},
            "Chassis / Roll Bar Paint": {},
            "Engine Enamels": {},
            "Epoxy & Quick Coat Paints": {},
            "Exhaust Paint": {},
            "Flameproof Coatings": {},
            "Pin Stripping Brushes & Oils": {},
            "Plastic Paints": {},
            "Prime Coat": {},
            "Wheely Bar Chalk Spray": {}
        }
    },
    "Hose & Fittings": {
        "Accessories": {
            "Block-Off Caps": {},
            "Hex Hose Finishers": {},
            "Hose End Sockets": {},
            "Inner Support Springs": {},
            "O-Rings & Seals": {},
            "Replacement Olives": {},
            "Washers": {}
        },
        "Adapters": {
            "BSP Adapters": {},
            "BSPP Adapters": {},
            "Flare Adapters": {},
            "Inverted Adapters": {},
            "NPT Adapters": {},
            "ORB Adapters": {},
            "Transmission Adapters": {},
            "Union Adapters": {}
        },
        "Fittings": {
            "Banjos": {},
            "Barb Fittings": {},
            "Bulkheads": {},
            "Couplers": {},
            "Extensions": {},
            "Flare Caps": {},
            "Low Profile": {},
            "NPT Fittings": {},
            "Plugs": {},
            "Push to Connect": {},
            "Quick Release": {},
            "Tee Fittings": {},
            "T-Pieces": {},
            "Y-Blocks": {}
        },
        "Hard Line": {
            "Aluminium Hard Line": {},
            "Coated Steel Hard Line": {},
            "Hardline Adapters": {},
            "Replacement Olives": {},
            "Stainless Hard Line": {},
            "Tube Sleeves & Nuts": {}
        },
        "Hoses": {
            "100 Series Stainless Braid": {},
            "111 Series Stainless Braid Cover": {},
            "120 Series Nylon": {},
            "200 Series PTFE Stainless Braid": {},
            "200 Series PTFE Coated Braid": {},
            "250 Series PTFE Nylon Braid": {},
            "400 Series Push Lock": {},
            "450 Series Nylon Braid": {},
            "500 Series Push Lock": {},
            "800 Series A/C Nylon Stainless Braid": {},
            "Kryptalon® Kevlar Braid": {},
            "Nylon Hose": {},
            "Reinforced Clear PVC": {},
            "Silicone Hoses": {}
        },
        "Hose Clamps": {
            "Aero Clamps": {},
            "Constant Tension Clamps": {},
            "EFI Clamps": {},
            "Hard Line Clamps": {},
            "P-Clamps": {},
            "Spring Clamps": {},
            "Stainless Hose Clamps": {},
            "T-Bolt Clamps": {}
        },
        "Hose Ends": {
            "100 Series Taper": {},
            "150 Series Full Flow Taper": {},
            "200 Series PTFE": {},
            "400 Series Push Lock": {},
            "500 Series Cutter": {},
            "510 Series Full Flow Push Lock": {},
            "550 Series Full Flow Cutter": {},
            "570 Series Full Flow PTFE": {},
            "880 Series ELITE Cutter": {},
            "Forged Male Taper": {},
            "Kryptalon® Series": {},
            "ORB to AN Hose Ends": {}
        },
        "Hose Separators": {
            "Dual Separators": {},
            "Stepped Dual Separators": {}
        },
        "Tools": {
            "Clamp Pliers": {},
            "Cutters": {},
            "Cutting Film": {},
            "Dust Caps & Plugs": {},
            "Flaring Tools": {},
            "Hose & Fitting Installation Tool": {},
            "Olive Installation Tool": {},
            "Pipe Beading Tools": {},
            "Pressure Testers & Tags": {},
            "Thread Paste & Sealant": {},
            "Tube Benders & Straighteners": {}
        }
    },
    "Oil System": {
        "Accumulators": {},
        "Air / Oil Separators (Catch Cans)": {},
        "Baffles & Crank Scrapers": {
            "Baffles": {},
            "Crank Scrapers": {}
        },
        "Block Adapters": {},
        "Breather Tanks": {},
        "Bypass Plates": {},
        "Drain Plugs": {},
        "Engine Dipsticks": {},
        "Filter Heads, Mounts & Brackets": {},
        "Fittings & Adapters": {},
        "Oil Cooler Adapters": {},
        "Oil Cooler Line Kits": {},
        "Oil Cooler Thread Inserts": {},
        "Oil Coolers": {},
        "Oil Filters": {
            "Cartridge Type": {},
            "In-Line Filters": {},
            "Scavenge Filters": {},
            "Spin-On Filters": {},
            "Filter Screens": {}
        },
        "Oil Filter Relocation Kits": {},
        "Oil Heaters": {},
        "Oil Pans / Sumps": {
            "Diecast": {},
            "Fabricated": {},
            "Stamped Steel": {}
        },
        "Oil Pan Accessories": {},
        "Oil Pumps": {
            "Dry Sump": {},
            "Electric": {},
            "External": {},
            "Internal - High Volume": {},
            "Internal - Low Volume": {},
            "Internal - STD Volume": {}
        },
        "Oil Pump Drives": {},
        "Oil Pump Pickups": {},
        "Oil Restrictors": {},
        "Oil Tanks": {},
        "Oil Thermostats": {},
        "Pulleys & Drive Belts": {},
        "Sandwich Adapters": {},
        "Vac-U-Pans": {},
        "Vacuum Pumps": {},
        "Windage Trays": {}
    },
    "Turbo, Supercharger & Nitrous": {
        "Turbochargers & Components": {
            "Bellmouths": {},
            "Block Off Plugs": {},
            "Blow-Off Valves": {},
            "Blow-Off Valve Adapters": {},
            "Blow-Off Valve Springs": {},
            "Boost Controllers": {},
            "Cast Elbows": {},
            "Flanges & Weld-On's": {},
            "Intercoolers": {},
            "Intercooler Clamps": {},
            "Locking Tabs": {},
            "Oil Feed Fittings": {},
            "Oil Feed / Water Lines": {},
            "Protector Screens": {},
            "Studs & Nuts": {},
            "Turbo Adapters": {},
            "Turbo Blankets": {},
            "Turbo Cores": {},
            "Turbo Drain Bulkheads": {},
            "Turbo Drain Hoses": {},
            "Turbo Drain Line Kits": {},
            "Turbo Drains & Gaskets": {},
            "Turbo Gaskets": {},
            "Turbo Housings": {},
            "Turbo Restrictors": {},
            "Turbo Timers": {},
            "Turbochargers": {},
            "V-Band Clamps": {},
            "Wastegate Blankets": {},
            "Wastegate Pipes": {},
            "Wastegate Spare Parts": {},
            "Wastegate Springs": {},
            "Water Fittings": {},
            "Water / Methanol Injection": {},
            "Compressor Wheels & Sensors": {},
            "Wastegates & Accuators": {},
            "Charge Air Controls": {}
        },
        "Superchargers & Components": {
            "Air Bleeds": {},
            "Belt Guards": {},
            "Belts": {},
            "Block-Off / Delete Plates": {},
            "Blower Manifolds & Spacers": {},
            "Blower Restraints": {},
            "Brackets & Plates": {},
            "Burst Panels": {},
            "Carb Adapters": {},
            "Crank Hubs": {},
            "Crank Supports": {},
            "Drive Accessories": {},
            "Drive Kits": {},
            "Fuel Pump Extensions": {},
            "Gaskets & Spacers": {},
            "Hubs & Pulleys": {},
            "Intercoolers": {},
            "Relief Valves": {},
            "Remote Mount Starters": {},
            "Snouts & Couplers": {},
            "Stud Kits": {},
            "Supercharger Kits": {},
            "Supercharger Parts": {},
            "Superchargers": {}
        },
        "Nitrous Kits & Components": {
            "Bar Mounts": {},
            "Bottle Blankets": {},
            "Bottle Brackets": {},
            "Bottle Components": {},
            "Bottle Heaters": {},
            "Bottles": {},
            "Distrubution Blocks": {},
            "Hoses & Fittings": {},
            "Injector Plates": {},
            "Nitrous & Fuel Pressure Gauges": {},
            "Nitrous Controllers": {},
            "Nitrous Filters": {},
            "Nitrous Jets": {},
            "Nitrous Kits": {},
            "Purge Kits": {},
            "Refill Stations": {},
            "Relief Valves & Burst Panels": {},
            "Solenoids": {},
            "Switches": {},
            "Nozzle & Fittings ": {}
        }
    },
    "Safety Equipment": {
        "Arm Restraints": {},
        "Dial-In Board & Accessories": {},
        "Door Restraints": {},
        "Engine Nappies": {},
        "Fire Systems": {
            "Bar Mounts": {},
            "Bottles": {},
            "Cables": {},
            "Fire Suppression Systems": {},
            "Fittings & Accessories": {},
            "Injector Heads": {},
            "Mounts & Clamps": {},
            "Nozzels": {}
        },
        "Gear Bags": {},
        "Gloves": {
            "Crew Gloves & Sleeves": {},
            "Racing Gloves": {}
        },
        "Helmets": {
            "Helmets": {},
            "Helmet Accessories": {},
            "Helmet Bags": {},
            "Restraints, HANS & Hybrids": {},
            "Visors & Tear-Offs": {}
        },
        "Neck Braces": {},
        "Parachutes": {
            "Chute Bags": {},
            "Flags / Tags": {},
            "Parachute Accessories": {},
            "Parachutes": {},
            "Pilot Chutes": {},
            "Release Cables": {}
        },
        "Race Seats": {
            "Aluminium Seats": {},
            "Brackets & Mounts": {},
            "Knee & Arm Guards": {},
            "Poly Seats": {},
            "Seat Accessories": {},
            "Seat Covers": {}
        },
        "Race Suits": {
            "Jackets": {},
            "One-Piece Suits": {},
            "Pants": {}
        },
        "Racing Harness / Seatbelts": {
            "Camlock Type": {},
            "Latch Type": {},
            "Mounting Kits": {}
        },
        "Rock Screens": {},
        "Roll Bar Padding": {},
        "Shoes": {
            "Crew Shoes": {},
            "Drag Boots": {},
            "Racing Shoes": {}
        },
        "Transmission Shields / Blankets": {},
        "Underwear": {
            "Bottom Underwear": {},
            "Head Socks / Balaclava": {},
            "Socks": {},
            "Top Underwear": {}
        },
        "Window Nets": {}
    },
    "Tools & Shop Equipment": {
        "Engine Tools": {
            "Conrod Tools": {},
            "Crankshaft Turning Sockets / Nuts": {},
            "Cylinder Head CC Kits": {},
            "Cylinder Head Stands": {},
            "Engine Building Tools": {},
            "Engine Block Offs": {},
            "Engine Rotation Adapters": {},
            "Engine Storage Bags": {},
            "Harmonic Balancer Puller / Installers": {},
            "Leakage Testers": {},
            "Oil Filter Cutters": {},
            "Oil Primers": {},
            "Piston & Ring Tools": {},
            "Rear Cover Alignment Tool": {},
            "Timing Cover Alignment Tool": {},
            "Top Dead Center Tools": {}
        },
        "Valve Train Tools": {
            "Cam Checking Tool": {},
            "Camshaft Bearing Installation Tools": {},
            "Camshaft Installation Tools": {},
            "Head Off Checking Tool": {},
            "Lifter Retaining Tool": {},
            "Pushrod Length Checkers": {},
            "Rocker Arm Stud Remover": {},
            "Valve Lash Tools": {},
            "Valve Seal Installer": {},
            "Valve Spring Tools": {},
            "Valve Train Trays": {},
            "Valve Grinding Tools": {}
        },
        "Fuel System Tools": {
            "Carb Caps": {},
            "Carb Drain Cup": {},
            "Carb Float Adjuster": {},
            "Carb Work Stand": {},
            "EFI Disconnect Tool": {},
            "Fuel Content Testers": {},
            "Fuel Pressure Gauge": {},
            "Jet Holders": {},
            "Nitrous Tools": {},
            "Power Valve Remover": {}
        },
        "Ignition & Electrical Tools": {
            "Ignition Lead Crimpers": {},
            "Spark Plug Tools": {},
            "Terminal Tools": {},
            "Timing Lights": {},
            "Wire Cutters": {},
            "Wire Strippers": {}
        },
        "Hose, Hardline & Fabrication Tools": {
            "Clamp Pliers": {},
            "Cutters": {},
            "Cutting Film": {},
            "Dust Caps & Plugs": {},
            "Flaring Tools": {},
            "Hose & Fitting Installation Tool": {},
            "Olive Installation Tool": {},
            "Pipe Beading Tools": {},
            "Pressure Testers & Tags": {},
            "Thread Paste & Sealant": {},
            "Tube Benders & Straighteners": {},
            "Tubing & Pipe Notcher": {}
        },
        "Pit & Trailer Accessories": {
            "Cabinets, Holders & Mounts": {},
            "Funnels": {},
            "Portable Air Tanks": {},
            "Practice Trees": {},
            "Scales": {},
            "Speedway Tools": {},
            "Storage Racks & Shelves": {},
            "Tie-Downs & Straps": {},
            "Tool Trays": {},
            "Trailer Accessories": {},
            "Tyre Covers": {},
            "Tyre Gauges": {},
            "Work Tables": {},
            "Weather Stations & Track Meters": {}
        },
        "Workshop Tools": {
            "Angle Finder & Level": {},
            "Battery Tools": {},
            "Car Ramps": {},
            "Cases": {},
            "Chassis Stands": {},
            "Coil Over Shock Adjuster": {},
            "Deburring Tool": {},
            "Diff Centre Assembly Stand": {},
            "Dzus Fastener Tools": {},
            "Engine Lifting & Support": {},
            "Engine Stands & Cradles": {},
            "Fender / Guard Covers & Seat Covers": {},
            "Power Speed Calculator": {},
            "Qwik Lift Vehicle Jacks": {},
            "Replica Engines & Transmissions": {},
            "Safety Wire & Pliers": {},
            "Sockets": {},
            "Tapes": {},
            "Thread Identification Kits": {},
            "Tyre Gauges & Monitors": {},
            "Vice Jaws": {},
            "Work Lights / Torches": {},
            "Workshop Creeper": {},
            "Workshop Trolley": {},
            "Wrenches & Spanners": {},
            "Axle Cutting Tools": {},
            "Transfer Pumps": {},
            "Turbo Tools": {}
        }
    },
    "Transmission & Driveline": {
        "Automatic Transmission": {
            "Automatic Transmissions": {},
            "Bands": {},
            "Bellhousings": {},
            "Cases & Extension Housings": {},
            "Clutch Hubs": {},
            "Control Units": {},
            "Converters": {},
            "Delay Boxes": {},
            "Dipsticks": {},
            "Dump Valves": {},
            "Expansion Tanks": {},
            "Fittings & Adapters": {},
            "Flexplates": {},
            "Friction & Steel Plates": {},
            "Front Oil Pumps": {},
            "Input Shafts": {},
            "Inspection Covers": {},
            "Kickdown Cables": {},
            "Lock Ups": {},
            "Planetary Gear": {},
            "Remote Filters": {},
            "Sandwich Plates": {},
            "Servos": {},
            "Shift Kits": {},
            "Speedo Drives & Gears": {},
            "Sprags": {},
            "Trans Brakes": {},
            "Trans Coolers": {},
            "Trans Pans & Gaskets": {},
            "Trans Shields & Blankets": {},
            "Transmission Filters": {},
            "Transmission Mounts": {},
            "Transmission Parts": {},
            "Vacuum Modulators": {},
            "Valve Bodies": {},
            "Yokes": {}
        },
        "Differential": {
            "Axle Retaining Plates": {},
            "Axles": {},
            "Axles Bearings": {},
            "Axles Tubes & Ends": {},
            "Bearings, Shims, Sleeves & Spacers": {},
            "Breathers": {},
            "Cases": {},
            "Centre Sections": {},
            "Detroit Lockers": {},
            "Detroit Truetrac": {},
            "Fasteners": {},
            "Floater Kits": {},
            "Full Spools": {},
            "Housings & Braces": {},
            "Mini Spools": {},
            "Pinion Yokes": {},
            "Powertrax": {},
            "Quick Change Assemblies": {},
            "Quick Change Gears & Components": {},
            "Ring & Pinion Gears": {},
            "Seals & Gaskets": {},
            "Sprint Car Driveline": {},
            "Spring Perches": {},
            "Wheel Studs": {},
            "Installation & Overhaul Kits": {},
            "Pinion Supports, Bearings & Accessories": {}
        },
        "Driveshaft": {
            "Couplers": {},
            "Driveshafts / Tailshafts": {},
            "Girdles": {},
            "Pick Up Collars": {},
            "Tailshaft Loops": {},
            "U-Bolts": {},
            "Universal Joints": {}
        },
        "Manual Transmission": {
            "Adapters & Valves": {},
            "Bellhousings": {},
            "Clucth Forks & Pivots": {},
            "Clutch Discs": {},
            "Clutch Kits": {},
            "Clutch Reservoirs & Slave Cylinders": {},
            "Flywheels": {},
            "Manual Transmissions": {},
            "Pressure Plates": {},
            "Throwout Bearings": {},
            "Yokes": {},
            "Spigot Bushes / Pilot Bearings": {},
            "Transmission Replacement Parts": {}
        },
        "Shifters": {
            "Automatic Shifters": {},
            "Bracket & Levers": {},
            "Cables": {},
            "CO2 Bottles, Mounts & Accessories": {},
            "Covers & Boots": {},
            "Drop Box & Plates": {},
            "Gate Plates": {},
            "Gear Indicators": {},
            "Handles": {},
            "Knobs": {},
            "Manual Shifters": {},
            "Shifter Replacement Parts": {},
            "Switches": {}
        }
    },
    "Wheels & Tyres": {
        "Wheels": {
            "Front Runners": {},
            "Racing Wheels": {},
            "Speedway Wheels & Hubs": {},
            "Steel Wheels": {},
            "Street Wheels": {}
        },
        "Wheel Accessories": {
            "Beadlocks": {},
            "Caps": {},
            "Knock-Offs": {},
            "Tools": {},
            "Trim Rings": {},
            "Valve Caps": {},
            "Valve Stems": {},
            "Wheel Centers & Bolts": {},
            "Wheel Covers": {},
            "Wheel Spacers": {},
            "Wheel Studs, Nuts & Washers": {}
        },
        "Tyres": {
            "Drag Tyres": {},
            "Front Runners": {},
            "Street Tyres": {}
        },
        "Tyre Accessories": {
            "Tubes": {},
            "Tyre Covers": {},
            "Tyre Grooving": {},
            "Tyre Screws": {}
        }
    }
}

export const allCategories = VPWCategories;

export const MAX_CATEGORY_LEVELS = 3;