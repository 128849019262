import * as React from 'react';
import Card from '@mui/material/Card';
import { Accordion, AccordionDetails, AccordionSummary, CardHeader, CardMedia, CircularProgress, Divider, Step, StepLabel, Stepper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Unstable_Grid2';
import { green, grey, red } from '@mui/material/colors';
import { addToCart, clearCart, getAggregatedProductsInCart, getNumberOfProductsInCart, getProductsInCart, getShippingCost, getSubTotal, removeFromCart } from './Cart';
import { useTheme } from '@emotion/react';
import { getFormattedPrice } from '../Utilities';

const CartItems = ({ aggCartList, setAggCartList, setAnnotations, xs, sm, md, lg }) => {

    return <Grid container>
        {aggCartList.map(agCartItem => (
            <Grid xs={xs} sm={sm} md={md} lg={lg} sx={{ p: 1 }}>
                <Card sx={{ p: 1, height: "100%" }} variant='outlined'>
                    <div style={{ display: "flex", height: "100%" }}>
                        <img style={{ width: 96, objectFit: "contain" }} src={agCartItem.cartProduct.selectedProduct.image[0]} />

                        <Box sx={{ flex: 1, pl: 1, position: 'relative', display: "flex", flexDirection: "column" }}>

                            {/* Product Name */}
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="h6"><b>{agCartItem.cartProduct.selectedProduct.name}</b></Typography>
                                <Typography variant="subtitle1">{getFormattedPrice(agCartItem.cartProduct.selectedProduct.price)}</Typography>
                            </div>

                            {/* To Suit */}
                            <Accordion disableGutters elevation={0} sx={{
                                '.MuiAccordionDetails-root': {},
                                '.MuiAccordionSummary-root': { minHeight: 0, height: "1em" },
                                '&:before': {
                                    display: 'none'
                                }
                            }} style={{}}>
                                <AccordionSummary style={{ padding: 0, margin: 0 }} aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="subtitle2">{agCartItem.cartProduct.selectedVehicleFilter.make + " " + agCartItem.cartProduct.selectedVehicleFilter.model + " " + agCartItem.cartProduct.selectedVehicleFilter.series} </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 0, margin: 0 }}>
                                    <Typography variant='body' sx={{ paddingLeft: 1, fontSize: "0.8em", m: 0, color: grey[600] }}>{agCartItem.cartProduct.selectedVehicleFilter.engine}</Typography>
                                    <div />
                                    <Typography variant='body' sx={{ paddingLeft: 1, fontSize: "0.8em", color: grey[600] }}>{agCartItem.cartProduct.selectedVehicleFilter.variant}</Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Divider sx={{ m: 1 }} />
                            {/* Product Configurations */}
                            {agCartItem.cartProduct.selectedConfig.map(cfg => (
                                <Box mt={0.5}>
                                    <Typography variant="body2">{cfg.config.name}</Typography>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography variant='body2' sx={{ paddingLeft: 2, color: grey[600] }}> {cfg.configOption.name}</Typography>
                                        <Typography variant='body2' sx={{ color: grey[600] }}>{(cfg.configOption.price >= 0) ? ("   +" + getFormattedPrice(cfg.configOption.price)) : ("   -" + getFormattedPrice(Math.abs(cfg.configOption.price)))}</Typography>
                                    </div>
                                </Box>
                            ))}

                            {!agCartItem.cartProduct.selectedConfig.length && <Typography variant='subtitle1'>No configuration options applied</Typography>}

                            <Divider sx={{ m: 1 }} />
                            {/* QTY MODIFIERS */}

                            {setAggCartList && <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', height: "2em" }}>
                                <div style={{ flex: 1 }} />
                                <Button sx={{ m: 1, minWidth: "2em" }} variant="outlined" onClick={() => {
                                    removeFromCart(agCartItem.cartProduct);
                                    setAggCartList(getAggregatedProductsInCart())
                                    setAnnotations({ cart: getNumberOfProductsInCart() })
                                }}>-</Button>
                                <Button sx={{ m: 1, minWidth: "2em" }} variant="outlined" onClick={() => {
                                    addToCart(agCartItem.cartProduct)
                                    setAggCartList(getAggregatedProductsInCart())
                                    setAnnotations({ cart: getNumberOfProductsInCart() })
                                }}>+</Button>
                            </Box>}

                            {/* Grand Total */}
                            <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "flex-end" }}>
                                <div style={{ flex: 1 }} />
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                    <Typography variant="body2">{agCartItem.quantity + " units x " + getFormattedPrice(agCartItem.cartProduct.selectedProductPrice)}</Typography>
                                    <Typography variant="body2">{getFormattedPrice((agCartItem.quantity * agCartItem.cartProduct.selectedProductPrice))}</Typography>
                                </div>
                            </Box>

                        </Box>
                    </div>
                </Card>
            </Grid>
        ))}
    </Grid>
}

const CartSubTotalsByOrder = ({ order }) => {
    return <h5 style={{ textAlign: "end" }}>{"Subtotal: " + getFormattedPrice(order.subtotal)}
        <br />
        <br />
        {"Shipping: " + getFormattedPrice(order.shipping)}
        <br />
        <br />
        {"Order Total: " + getFormattedPrice(order.total)}
    </h5>
}

const CartSubTotalsByCart = () =>{
    const theme = useTheme()

    return  <Stack sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", pl: 2, pr: 2, backgroundColor: theme.palette.action.disabledBackground, mt: 1 }}>
    <h5 style={{ textAlign: "end" }}>{"Subtotal: " + getFormattedPrice(getSubTotal())}
        <br />
        <br />
        {"Shipping: " + getFormattedPrice(getShippingCost())}
        <br />
        <br />
        {"Order Total: " + getFormattedPrice((getSubTotal() + getShippingCost()))}
    </h5>

</Stack>
}

export {
    CartItems,
    CartSubTotalsByOrder,
    CartSubTotalsByCart
}