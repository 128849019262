import axios from 'axios';
import { API_ENDPOINT, fetchPlatformData } from '../Platforms';

const getOrders = async ({ authorization }) => {
    const res = await axios.post("http://" + API_ENDPOINT + "/admingetorders", JSON.stringify(authorization), {
        headers: {
            "content-type": "application/json"
        }
    })

    const response = res.data;

    // Sort so that newest orders are at the top based on the order dates.
    response.orders.sort(function (a, b) { return b.date - a.date })


    const orderObj = {}
    response.orders.forEach(order => {
        const { _id } = order;
        orderObj[_id] = {
            orderId: _id,
            orderStatus: order.status,
            paymentStatus: order.orderInfo.payment.status,
            myobOrderNum: order.myobOrderNum,
            shippingCarrier: order.orderInfo.delivery.type,
            trackingNumber: order.orderInfo.delivery.tracking
        }
    })

    return {
        orders: response.orders,
        orderObj: orderObj
    }
}

const sendMessageAdmin = async ({ authorization, id, value }) => {
    const currentMsg = {};

    // Set message properties.
    currentMsg.orderId = id;
    currentMsg.user = authorization.firstName + " " + authorization.lastName;
    currentMsg.msg = value;

    const res = await axios.post("http://" + API_ENDPOINT + "/adminupdatemessages", JSON.stringify({ ...authorization, ...currentMsg }), {
        headers: {
            "content-type": "application/json"
        }
    })
}

const sendStaffMessageAdmin = async ({ authorization, id, value }) => {
    const currentMsg = {};

    // Set message properties.
    currentMsg.orderId = id;
    currentMsg.user = authorization.firstName + " " + authorization.lastName;
    currentMsg.msg = value;

    const res = await axios.post("http://" + API_ENDPOINT + "/adminupdatemessagesinternal", JSON.stringify({ ...authorization, ...currentMsg }), {
        headers: {
            "content-type": "application/json"
        }
    })
}

const sendMessageUser = async (id, user, value) => {
    const currentMsg = {};

    // Set message properties.
    currentMsg.orderId = id;
    currentMsg.user = user
    currentMsg.msg = value;

    //  setWaitingAPI(true);
    const res = await axios.post("http://" + API_ENDPOINT + "/updatemessages", JSON.stringify(currentMsg), {
        headers: {
            "content-type": "application/json"
        }
    })

    return res
}

const login = async ({ username, password }) => {
    const res = await axios.post("http://" + API_ENDPOINT + "/adminauth", JSON.stringify({ username, password }), {
        headers: {
            "content-type": "application/json"
        }
    })
    const auth = res.data.authorization;
    return auth;

}

const getOrdersUsers = async () => {
    // Do the API call here.
    const res = await axios.post("http://" + API_ENDPOINT + "/getorders", "{}", {
        headers: {
            "content-type": "application/json"
        }
    })
    const orders = res.data.orders;

    // Sort so that newest orders are at the top based on the order dates.
    orders.sort(function (a, b) { return b.date - a.date })

    return orders
}

const getUserInformation = async () => {
    const res = await axios.post("http://" + API_ENDPOINT + "/getuser", "{}", {
        headers: {
            "content-type": "application/json"
        }
    })
    return res.data.user
}

const editUserInformation = async ({ userInfo }) => {

    const res = await axios.post("http://" + API_ENDPOINT + "/updateuser", JSON.stringify(userInfo), {
        headers: {
            "content-type": "application/json"
        }
    })

    return res;
}

const updateOrder = async ({ authorization, orderId, formValues }) => {
    const res = await axios.post("http://" + API_ENDPOINT + "/adminupdateorder", JSON.stringify({ ...authorization, orderId, ...formValues }), {
        headers: {
            "content-type": "application/json"
        }
    })
    return res.data;
}

const submitCustomOrder = async (customOrder) => {
    const res = await axios.post("http://" + API_ENDPOINT + "/customorder", JSON.stringify(customOrder), {
        headers: {
            "content-type": "application/json"
        }
    })
    return res.data;
}

const sendOrders = async (order) => {
    const res = await axios.post("http://" + API_ENDPOINT + "/order", JSON.stringify(order), {
        headers: {
            "content-type": "application/json"
        }
    })

    return res.data.orderNumber
}

export {
    getOrders,
    getOrdersUsers,
    sendMessageAdmin,
    sendStaffMessageAdmin,
    login,
    sendMessageUser,
    getUserInformation,
    editUserInformation,
    updateOrder,
    submitCustomOrder,
    sendOrders
}