
const cartProductsKey = "cart-products"

// Adds a product to the cart
function addToCart(product) {
    let cartContents = getProductsInCart();
    cartContents.push(product);

    localStorage.removeItem(cartProductsKey);
    localStorage.setItem(cartProductsKey, JSON.stringify(cartContents));
}

// Removes the product + specific configuration from the cart
function removeFromCart(product) {
    let cartContents = getProductsInCart();

    // Find the product in the cart
    for (let i = 0; i < cartContents.length; i++) {
        const currentProduct = cartContents[i];
        if (JSON.stringify(currentProduct) === JSON.stringify(product)) {
            cartContents.splice(i,1);
            break;
        }
    }
    localStorage.removeItem(cartProductsKey);
    localStorage.setItem(cartProductsKey, JSON.stringify(cartContents));
}

// Returns the array of products stored in the cart
function getProductsInCart() {
    let retVal = JSON.parse(localStorage.getItem(cartProductsKey));

    if (retVal == null)
        retVal = [];
    return retVal;
}

// Returns an aggregation of items in cart
function getAggregatedProductsInCart() {
    let cartContents = getProductsInCart();
    let aggregatedList = [];

    // Structure: {quantity: int, productObject: {} )

    // Iterate over the list.
    for (let i = 0; i < cartContents.length; i++) {
        const currentProduct = cartContents[i];
        let found = false;

        // Product found - bump the quantity up
        for (let j = 0; j < aggregatedList.length; j++) {
            if (JSON.stringify(currentProduct) === JSON.stringify(aggregatedList[j].cartProduct)) {
                aggregatedList[j].quantity++;
                found = true;
                break;
            }
        }
        // Product not found... insert...
        if (!found) {
            aggregatedList.push({ quantity: 1, cartProduct: currentProduct })
        }
    }
    return aggregatedList;
}

// Removes all product entries from the cart
function clearCart() {
    localStorage.removeItem(cartProductsKey);
}

function getNumberOfProductsInCart() {
    return getProductsInCart().length;
}

function getShippingCost() {
    let cartContents = getProductsInCart();
    let shippingCost = 0;

    for (let i = 0; i < cartContents.length; i++)
        shippingCost += cartContents[i].selectedProduct.shippingCost;
    return shippingCost;
}

function getSubTotal() {
    let cartContents = getProductsInCart();
    let subtotal = 0;

    for (let i = 0; i < cartContents.length; i++)
        subtotal += cartContents[i].selectedProductPrice;
    return subtotal;
}

export { addToCart, removeFromCart, getProductsInCart, getAggregatedProductsInCart, clearCart, getNumberOfProductsInCart, getShippingCost, getSubTotal };