import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useRef } from 'react';
import { updateOrder } from '../api/rest';
import { getFormValues } from '../util/forms';
import DefaultFormMap from '../components/DefaultFormMap';

const CustomerTab = ({ authorization, order, wrapAPIAction }) => {
    const form = useRef(null);
    const disabled = authorization == undefined || authorization.type != "administrator";

    const items = [
        { name: "customerFirstName", label: "First Name", defaultValue: order.orderInfo.customer.firstName, disabled },
        { name: "customerLastName", label: "Last Name", defaultValue: order.orderInfo.customer.lastName, disabled },
        { name: "customerPhoneNumber", label: "Phone Number", defaultValue: order.orderInfo.customer.phoneNumber, disabled },
        { name: "customerEmail", label: "Emain Address", defaultValue: order.orderInfo.customer.email, disabled }
    ]
    
    return <Box>
        <DefaultFormMap form={form} items={items} />
        {!disabled && <Button sx={{ mt: 1 }} variant="outlined"
            onClick={() => wrapAPIAction(async() => updateOrder({ authorization, orderId: order._id, formValues: getFormValues(form) }))}>
            Save Changes</Button>}
    </Box>
}

export default CustomerTab