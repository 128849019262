// Conditionally formatted based on elapsed time
function getTimeStamp(time) {
    const timeDiff = (Math.abs(new Date() - new Date(time)) / 36e5);

    const split = new Date(time).toLocaleTimeString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).split(', ');
    const dayofweek = split[0];
    const mmddyy = split[1].split('/');
    const hhmmss = split[2].split(' ')[0].split(':');
    const ampm = split[2].split(' ')[1];

    // Return just HH:MM:SS AM/PM
    if (timeDiff <= 24) {
        return parseInt(hhmmss[0]) + ":" + hhmmss[1] + " " + ampm;
    }
    // Within 72 hours ... display Day HH:MM AM/PM
    else if (timeDiff <= 72) {
        return dayofweek + " " + parseInt(hhmmss[0]) + ":" + hhmmss[1] + " " + ampm;
    }
    // Within a month... return Day DD/MM
    else if (timeDiff <= 672) {
        return dayofweek + " " + mmddyy[1] + "/" + mmddyy[0];
    }
    // Otherwise just return full time
    else {
        return new Date(time).toLocaleString();
    }
}

function getFormattedPrice(price) {
    if( price == undefined || price.length == 0 )
        return "$0.00";
    else return "$" + price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const targetWait = 0;
const wrapAPICall = async (funct) => {
    return new Promise(async (resolve, reject) => {
        const startTime = new Date();
        let res, isErr = false;
        try {
            res = await funct;
        } catch (err) {
            res = err
            isErr = true
        }
        const duration = new Date() - startTime;
        const wait = targetWait - duration

        if (duration < targetWait && !isErr) return setTimeout(() => resolve(res), wait)
        if (duration < targetWait && isErr) return setTimeout(() => reject(res), wait)

        if (!isErr) return resolve(res)
        if (isErr) return reject(res);
    })

}

const isPopulated = (val) => val.length < 1;

export { getTimeStamp, getFormattedPrice, wrapAPICall, isPopulated }