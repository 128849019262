import * as React from 'react';
import Carousel from 'react-material-ui-carousel'
import Card from '@mui/material/Card';
import { CircularProgress, IconButton, Snackbar } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Typography from '@mui/material/Typography';
import StickyHeader from '../components/stickyHeader';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { getFilterById } from '../Database/Vehicles/vehicleList';
import { Box } from '@mui/system';
import { green } from '@mui/material/colors';
import { addToCart, getNumberOfProductsInCart } from '../Structures/Cart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { API_ENDPOINT } from '../Platforms';
import { useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown'


const ProductDetails = (props) => {

    const [productSpec, setProductSpec] = useState({ price: null, configuration: null })
    const [open, setOpen] = React.useState(false);
    const [productList, setProductList] = useState([]);
    const navigate = useNavigate()

    const params = useParams()
    const vFilter = getFilterById(params.fid);
    let product = null;


    // Fetch list of products from the API.
    useEffect(() => {
        axios.post("http://" + API_ENDPOINT + "/getproducts", JSON.stringify(vFilter), {
            headers: {
                "content-type": "application/json"
            }
        }).then(res => {
            setProductList(res.data.products)
            initProduct();
        })
    }, [])

    // Initialises the product and filter options
    function initProduct() {
        if (vFilter != null) {
            // Find the product within the filtered product list.
            // Doing this search manually gaurantees that the product is valid for the given filter
            // This prevents product enumeration spoofing causing mismatched filter / products
            for (let i = 0; i < productList.length; i++) {
                if (productList[i]._id == params.pid) {
                    product = productList[i];
                    break;
                }
            }

            if (product != null) {
                // Product spec init here
                if (productSpec.price == null)
                    productSpec.price = product.price;
                if (productSpec.configuration == null)
                    productSpec.configuration = initProductConfiguration();
            }
        }
    }

    // Set the configuration defaults here for the dropdown
    function initProductConfiguration() {
        const configuration = [];
        for (let i = 0; i < product.configurableOptions.length; i++) {
            let config = product.configurableOptions[i];
            let configOption = config.options[0];
            configuration.push({ config, configOption });
        }
        return configuration
    }

    function addToCartButton() {

        setOpen(true)
        let cartEntry = {
            selectedVehicleFilter: vFilter,                         // Vehicle filter for the product
            selectedProduct: product,                               // Product
            selectedProductPrice: productSpec.price,                // Price which includes configurable options
            selectedProductLink: window.location.href,              // Link to the product page
            selectedConfig: productSpec.configuration               // Filter combinations
        }
        addToCart(cartEntry);
        props.setAnnotations({ cart: getNumberOfProductsInCart() })
    }

    // Gets the product price after the configurations have been selected
    function getProductPrice() {
        let productPrice = product.price;

        if (productSpec.configuration != undefined) {
            for (let i = 0; i < productSpec.configuration.length; i++)
                productPrice += productSpec.configuration[i].configOption.price;
        }
        return productPrice;
    }

    function isAddToCartValid() {
        if (product.stockStatus == 0)
            return false;
        // Add any other validations here.
        return true;
    }

    // Selecting configurable options function
    function onMenuChange(config, e) {

        // Store the Product Configuration that the user selected
        let configOption = null;
        for (let i = 0; i < config.options.length; i++) {
            if (config.options[i].id == e.target.value)
                configOption = config.options[i];
        }

        // Store the users configurable options selection
        if (configOption != null) {
            // Remove old entries if they exist first.
            for (let i = 0; i < productSpec.configuration.length; i++) {
                if (productSpec.configuration[i].config.id == config.id) {
                    productSpec.configuration.splice(i, 1);
                    break;
                }
            }
            // Now add the new / updated configuration
            productSpec.configuration.push({ config, configOption });
        }

        // Store the price for the configuration
        productSpec.price = getProductPrice();
        setProductSpec({ ...productSpec })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };

    const action = (
        <div style={{ width: '100%', display: "flex", flexDirection: "row" }}>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => navigate("/store/cart")}
            >
                <ShoppingCartIcon fontSize="small" />
            </IconButton>
        </div>
    );

    if (productList.length == 0)
        return <CircularProgress thickness={2} size={"5em"} />

    else {
        initProduct();
        if ((product == null) || (vFilter == null)) return null;

        else return <Stack>
            <StickyHeader label={product.name} />

            <Grid spacing={{ xs: 1 }} container>
                <Grid xs={6} sm={6} >
                    <Carousel animation='fade' navButtonsAlwaysInvisible={product.image.length == 1} navButtonsAlwaysVisible={product.image.length > 1} interval='1000000' >
                        {product.image.map((img, i) => <img style={{ width: "100%", height: "100%" }} src={img} />)}
                    </Carousel>
                </Grid>

                <Grid xs={12} sm={6}>

                    {/* Brand Image */}
                    <img style={{ width: 200 }} src={"/brands/KN-Filters.jpg"} />

                    {/* Part Number */}
                    <Typography variant="h6">{"Part Number: " + product.partNumber}</Typography>

                    {/*Vehicle Configuration Filter*/}
                    <Card variant='outlined' sx={{ padding: 2 }}>
                        <Typography variant="h6">To Suit:</Typography>

                        <Typography variant="body1" >{"Make: " + vFilter.make}</Typography>
                        <Typography variant="body1" >{"Model: " + vFilter.model}</Typography>
                        <Typography variant="body1" >{"Series: " + vFilter.series}</Typography>
                        <Typography variant="body1" >{"Engine: " + vFilter.engine}</Typography>
                        <Typography variant="body1" >{"Variant: " + vFilter.variant}</Typography>
                    </Card>

                    {/* Product configurable options */}
                    <Grid container>

                        {/* <TODO> HIDE THIS CARD IF product.configurableOptions.length() == 0 */}
                        {product.configurableOptions.length != 0 && <Card variant='outlined' sx={{ pt: 2, pb: 2, width: "100%", mt: 1 }}>

                            <Typography variant="h6" sx={{ pl: 2, pr: 2 }} >Upgrades</Typography>

                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {product.configurableOptions.map((cfgOption, i) => (
                                    <Grid sx={{ display: "flex" }} xs={12} sm={12}>
                                        <TextField
                                            id="standard-select-currency"
                                            select
                                            label={cfgOption.name + ((cfgOption.stockStatus == 0) ? " - SOLD OUT" : "")}
                                            variant="outlined"
                                            disabled={cfgOption.stockStatus == 0}
                                            defaultValue={0}
                                            onChange={(e) => onMenuChange(cfgOption, e)}
                                            sx={{ flexGrow: 1, mt: 1 }} >

                                            {cfgOption.options.map((options, i) => (
                                                <MenuItem disabled={options.stockStatus == 0} key={options.id} value={options.id}>
                                                    {options.name + ((options.stockStatus == 0) ? " - SOLD OUT" : "")}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                ))}
                            </div>
                        </Card>}

                        <Card variant='outlined' sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1, padding: 2, width: '100%' }}>

                            {/* Stock Status */}
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="h6" align='left' color={product.stockStatus == 1 ? green[700] : "#b30000"} >{product.stockStatus == 1 ? "In Stock" : "Sold Out"}</Typography>
                            </Box>

                            {/* Price */}
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="h5" align='right'>${productSpec.price}</Typography>
                            </Box>

                        </Card>

                        <Box sx={{ flex: 1, width: "100%", mt: 1, mb: 1 }}>
                            <Button sx={{ width: "100%" }} variant="outlined" disabled={!isAddToCartValid()} onClick={addToCartButton}>Add to cart</Button>
                        </Box>

                        <Snackbar
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            message={`${product.name} added to cart`}
                            action={action}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        />

                    </Grid>
                </Grid>
            </Grid>

            {/*Product Description*/}
            <Typography variant="h5" align="center"><b>DESCRIPTION</b></Typography>

            <Card variant='outlined' sx={{ padding: 2 }}>
                <ReactMarkdown>{product.productDescription}</ReactMarkdown>
            </Card>
        </Stack>
    }
};

export default ProductDetails;