import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccessibilityIcon from '@mui/icons-material/Accessibility';

const SocialIcons =  {
    Facebook: () => <FacebookIcon />,
    Phone: () => <LocalPhoneIcon />,
    Instagram: () => <InstagramIcon />,
    TikTok: () => <AccessibilityIcon/>
}

export default SocialIcons