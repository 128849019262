const getFormValues = (form) => {
    if (!form.current) return;

    const vals = {}
    Object.keys(form.current.elements).forEach(element => {
        const thisElement = form.current.elements[element]
        if (thisElement.tagName === "INPUT") {
            if (!thisElement.value) return
            vals[thisElement.name] = thisElement.value
        }
    })

    return vals;
}

export { getFormValues }