import { cleandb } from "./cleandb";
let db = cleandb;


function getVehicleMakes() {
    const list = [];
    const list2 = [];

    for (let i = 0; i < db.length; i++) {
        let current = db[i].make;
        if (list.indexOf(current) === -1)
            list.push(current)
    }
    list.sort();

    // Push popular makes to the top
    list2.push("HOLDEN");
    list2.push("HSV");
    list2.push("FORD");
    list2.push("FPV");

    for (let i = 0; i < list.length; i++) {
        let current = list[i];
        if (list2.indexOf(current) === -1)
            list2.push(current);
    }

    return list2;
}

function getVehicleModels(make) {
    const list = [];

    for (let i = 0; i < db.length; i++) {
        if (db[i].make == make) {
            let current = db[i].model;
            if (list.indexOf(current) === -1)
                list.push(current)
        }
    }
    return list.sort();
}

function getVehicleSeries(make, model) {
    const list = [];

    for (let i = 0; i < db.length; i++) {
        if ((db[i].make == make) && (db[i].model == model)) {
            let current = db[i].series;
            if (list.indexOf(current) === -1)
                list.push(current)
        }
    }
    return list.sort();
}

function getVehicleEngine(make, model, series) {
    const list = [];

    for (let i = 0; i < db.length; i++) {
        if ((db[i].make == make) && (db[i].model == model) && (db[i].series == series)) {
            let current = db[i].engine;
            if (list.indexOf(current) === -1)
                list.push(current)
        }
    }
    return list.sort();
}

function getVehicleVariant(make, model, series, engine) {
    const list = [];

    for (let i = 0; i < db.length; i++) {
        if ((db[i].make == make) && (db[i].model == model) && (db[i].series == series) && (db[i].engine == engine)) {
            let current = db[i].variant;
            if (list.indexOf(current) === -1)
                list.push(current)
        }
    }
    return list.sort();
}

// Returns the corresponding id for the specified filter combination
function getFilterId({make, model, series, engine, variant}) {
    if (!make || !model || ! series) return
    for (let i = 0; i < db.length; i++) {
        if ((db[i].make == make) && (db[i].model == model) && (db[i].series == series) && (db[i].engine == engine) && (db[i].variant == variant)) {
            return db[i].id;
        }
    }
    return null;
}

// Returns the filter entry based on its corresponding id
function getFilterById(id) {
    for (let i = 0; i < db.length; i++) {
        if (db[i].id == id) {
            return db[i];
        }
    }
    return null;
}

export { getVehicleMakes, getVehicleModels, getVehicleSeries, getVehicleEngine, getVehicleVariant, getFilterId, getFilterById };