import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import CardContent from '@mui/material/CardContent';
import { Avatar, ButtonBase, Card, CardActions, CardHeader, CardMedia, Container, Divider, Paper, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import StarRateIcon from '@mui/icons-material/StarRate';
import { useEffect } from 'react';

import ReceiptIcon from '@mui/icons-material/Receipt';

import GoogleIcon from '@mui/icons-material/Google';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { fetchPlatformData } from '../Platforms';
import SocialIcons from './SocialIcons';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Carousel from 'react-material-ui-carousel';

const mainImage = fetchPlatformData("siteImages").main;
const headerContent = fetchPlatformData("mainHeader");
const scrollTargetMultiplier = 2

const parallaxStrength = 350;
const parallaxStart = 0;
const granularity = 0.005

const Background = () => {
    const theme = useTheme()
    const [scrollPercent, setScrollPercent] = React.useState(0)
    const [slideOut, setSlideOut] = React.useState(false);

    useEffect(() => {
        const onScroll = (e) => {
            const thisScrollPercent = e.target.documentElement.scrollTop / window.visualViewport.height
            let scrollTarget;
            if (thisScrollPercent < parallaxStart) { scrollTarget = 0; }
            else {
                scrollTarget = (thisScrollPercent - parallaxStart) / (1 - parallaxStart)
            }

            thisScrollPercent > 0 ? setSlideOut(true) : setSlideOut(false)

            setScrollPercent(previousValue => {
                if (Math.abs(scrollTarget - previousValue) > granularity && scrollTarget * scrollTargetMultiplier <= 1) return scrollTarget;
                return previousValue
            })
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollPercent]);

    const breakAt = "xl"

    return (
        <React.Fragment>


            {/* Contact card */}
            <Card sx={{
                backgroundColor: theme.palette.primary.main, backdropFilter: fetchPlatformData("theme").backdropFilter,
                borderRadius: 2, padding: 2, flexDirection: "column", margin: 2, zIndex: 2, position: "relative"
            }} elevation={4} >
                <Typography variant="subtitle1" color="white" component="div">
                    <div style={{ display: "flex", alignContent: "center" }}>
                        <ReceiptIcon />
                        <Box sx={{ marginLeft: 1 }}>{headerContent.title}</Box>
                    </div>
                </Typography>
                <Typography variant="subtitle2" color="white" component="div" >
                    <Box sx={{ display: "flex", flexDirection: "row", marginTop: 1, justifyItems: "center" }}>
                        <GoogleIcon sx={{ marginRight: 1 }} />
                        <Tooltip title="Google Reviews">
                            <a href={headerContent.googleReviewsLink} style={{ color: "inherit", textDecoration: "none" }}>
                                <StarRateIcon />
                                <StarRateIcon />
                                <StarRateIcon />
                                <StarRateIcon />
                                <StarRateIcon />
                            </a>
                        </Tooltip>
                        <Typography variant="subtitle2" color="white" sx={{ marginLeft: "0.5em" }}></Typography>
                    </Box>
                </Typography>

                <Divider sx={{ mt: 2 }} />

                <Typography variant="subtitle2" color="white" component="div">
                    <Grid container>
                        {headerContent.storeLocations.map((store, i) => {
                            return (
                                <Grid xs={6} sx={{ marginTop: 2 }}>
                                    <Typography variant="subtitle1" >{store.name}</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "row", marginTop: 1 }}>
                                        {store.socials.map((social, i) => {
                                            return (
                                                <React.Fragment>
                                                    <a href={social.link} style={{ color: "inherit", textDecoration: "none" }}>
                                                        <Tooltip title={social.name}>
                                                            {SocialIcons[social.name]()}
                                                        </Tooltip>
                                                    </a >
                                                    <Box sx={{ marginRight: 3 }} />
                                                </React.Fragment>
                                            )
                                        })}
                                    </Box>
                                </Grid >)
                        })}
                    </Grid>
                </Typography>

                <Divider sx={{ mt: 2 }} />
                <Grid xs={12} md={6} variant='outlined' elevation={0} sx={{
                    display: "flex",
                    mt: 1, padding: 0, flexDirection: "column", color: "#fff", mb: -1
                }}>
                    {fetchPlatformData("customerQuotes") != undefined ?
                        <Carousel
                            animation='slide'
                            indicatorContainerProps={{
                                style: {
                                    display: "none"
                                }
                            }}
                            navButtonsProps={{
                                style: {
                                    backgroundColor: 'none',
                                    background: "none",
                                    color: "black"
                                }
                            }} >
                            {fetchPlatformData("customerQuotes").map(message => <Box sx={{
                                display: "flex", flexDirection: "row",
                                justifyContent: "space-between", pl: 2, pr: 2,
                                overflow: "hidden", alignItems: "center"
                            }}>
                                <FormatQuoteIcon sx={{ transform: "rotate(180deg)" }} />
                                <Typography variant='body2' sx={{
                                    textAlign: "center", overflow: "hidden",

                                }}>{message}</Typography>
                                <FormatQuoteIcon />
                            </Box>)}
                        </Carousel> : <></>}
                </Grid>
            </Card>

            <Container maxWidth={breakAt} disableGutters sx={{
                backgroundImage: mainImage,
                height: "100vh",
                position: "fixed",
                top: -scrollPercent * parallaxStrength,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                zIndex: -1,
                filter: fetchPlatformData("theme").backdropFilter ? `blur(${fetchPlatformData("theme").initialBlur + scrollPercent * 20}px)` : undefined,
                transition: "all 0.25s ease-out"
            }}>
            </Container>

        </React.Fragment>
    )
}

export default Background;