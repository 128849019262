import * as React from 'react';
import { Box, CircularProgress, Container, IconButton, LinearProgress, Stack, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';


const ProgressModal = (props) => {
    const [showBackground, setShowBackground] = useState(false)

    useEffect(() => {
        if (!props.display) return setShowBackground(false)

        return
        setTimeout(() => {
            setShowBackground(true)
        }, 2000)
    }, [props.display])


    return <React.Fragment>
        {props.display && <Box style={{ position: "fixed", top: 64, maxWidth: "1912px", width: "100%", zIndex: 9999999, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: showBackground ? "rgba(255,255,255,0.8)" : "none" }}>
            <LinearProgress color="primary" sx={{ width: "calc(100% + 8px)", height: 8, marginLeft: -4, marginRight: -4 }} />
        </Box>}
    </React.Fragment>

}

export default ProgressModal