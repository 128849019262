import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import StickyHeader from '../components/stickyHeader';
import { fetchPlatformData } from '../Platforms';
import { useRef } from 'react';
import { useState } from 'react';
import { Stepper, } from '../Structures/Stepper';
import { getFormValues } from '../util/forms';
import { submitCustomOrder } from '../api/rest';
import ProgressModal from '../components/ProgressModal';
import OrderComplete from '../components/OrderComplete';
import ShoppingCart from './ShoppingCart';

export default function HorizontalLinearStepper() {
    const form = useRef(null);

    return (
        <Box>
            <form ref={form}>
                <ShoppingCart type={"custom"} />
            </form>
        </Box>
    );
}