import * as React from 'react';
import Typography from '@mui/material/Typography';
import StickyHeader from '../components/stickyHeader';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button';
import { API_ENDPOINT, fetchPlatformData } from '../Platforms';
import { useState } from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import { clearCart, getAggregatedProductsInCart, getNumberOfProductsInCart, getShippingCost, getSubTotal } from '../Structures/Cart';
import { GoogleLogin } from '@react-oauth/google';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getFormValues } from '../util/forms';
import { CartItems, CartSubTotalsByCart } from '../Structures/CartItems';
import OrderComplete from '../components/OrderComplete';
import { Stepper } from '../Structures/Stepper'
import { getUserInformation, sendOrders, submitCustomOrder } from '../api/rest';
import ProgressModal from '../components/ProgressModal';
import { useEffect } from 'react';
import { isPopulated } from '../Utilities';

function ShoppingCart({ setAnnotations, type }) {

    const form = useRef(null);

    const [orderNumber, setOrderNumber] = useState(null)
    const [aggCartList, setAggCartList] = useState(getAggregatedProductsInCart())
    const [userProfile, setUserProfile] = useState(null)

    const navigate = useNavigate()

    const hasItems = aggCartList.length > 0

    const isLoggedIn = false;

    const additionalStepsStart = {
        normal: [{
            name: "Your Items",
            type: "custom",
            customNext: "CHECKOUT",
            nextDisabled: !hasItems,
            content: () => <div>
                <CartItems aggCartList={aggCartList} setAggCartList={setAggCartList} setAnnotations={setAnnotations} xs={12} sm={6} md={4} lg={3} />


                {!hasItems && <Typography sx={{ p: 2, pt: 4 }}>There's nothing in your cart. Add something from the <span style={{ fontWeight: 500 }} onClick={() => navigate("/store")}>store</span></Typography>}

                <CartSubTotalsByCart />

            </div>
        }],
        custom: fetchPlatformData("orderForm")
    }

    const standardSteps = [
        {
            name: "My Account",
            type: "custom",
            content: () => !isLoggedIn && <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
        },
        {
            name: "Fetching details",
            type: "custom",
            content: () => (<div>
                <ProgressModal display={!userProfile} />
            </div>),
            onActive: async () => {
                const profileRes = await getUserInformation()
                setUserProfile(profileRes)
            },
            nextDisabled: !userProfile
        },
        {
            name: 'Your Information',
            paragraphs: [{
                name: "My Information",
                type: "fields",
                items: [
                    { id: "firstName", name: "First Name", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                    { id: "lastName", name: "Last Name", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                    { id: "businessName", name: "Business Name (optional)", type: "textInput" },
                    { id: "phoneNumber", name: "Phone Number", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                    { id: "email", name: "Email", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) }
                ]
            }, {
                name: "Shipping Information",
                type: "fields",
                items: [
                    { id: "shippingAddress", name: "Address", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                    { id: "shippingSuburb", name: "Suburb", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                    { id: "shippingPostcode", name: "Postcode", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                    { id: "shippingState", name: "State", type: "dropDown", items: ["New South Wales", "Victoria", "Queensland", "Western Australia", "South Australia", "Australian Capital Territory", "Tasmania", "Northern Territory"], helperText: "must be selected", isError: (val) => isPopulated(val) },
                    { id: "shippingbillingsame", name: "Billing Address", type: "dropDown", items: ["Same", "Different"], helperText: "option has to be selected", trackState: true, isError: (val) => isPopulated(val) }]
            },
            {
                name: "Billing Address",
                type: "fields",
                visibleIf: (state) => state.shippingbillingsame === "Different",
                items: [
                    { id: "billingAddress", name: "Address", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                    { id: "billingSuburb", name: "Suburb", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                    { id: "billingPostcode", name: "Postcode", type: "textInput", helperText: "must be specified", isError: (val) => isPopulated(val) },
                    { id: "billingState", name: "State", type: "dropDown", items: ["New South Wales", "Victoria", "Queensland", "Western Australia", "South Australia", "Australian Capital Territory", "Tasmania", "Northern Territory"], helperText: "must be selected", isError: (val) => isPopulated(val) }]
            },
            {
                name: "Delivery Method",
                type: "fields",
                items: [
                    { id: "deliveryMethod", name: "Option", type: "dropDown", items: ["Store Pickup - Keysborough ONLY", "Delivery - $30 Flat rate"], helperText: "must be selected", isError: (val) => isPopulated(val) }]
            },
            {
                name: "Payment Method",
                type: "fields",
                items: [
                    { id: "paymentMethod", name: "Option", type: "dropDown", items: ["Cash On Pickup - Keysborough ONLY", "PayID - 0413566335", "Bank Deposit - [BSB: 123 123] [Account Number: 1234 5678]"], helperText: "must be selected", isError: (val) => isPopulated(val) }]
            }],
            mounted: userProfile
        },
        {
            name: "Order",
            type: "custom",
            customNext: "CHECKOUT",
            content: () => {
                return <OrderComplete orderNumber={orderNumber} handleReset={handleReset} />
            },
            onActive: async () => {
                if (type === "normal") {
                    let order = {};

                    order.orderedItems = getAggregatedProductsInCart();
                    order.formvalues = getFormValues(form);

                    const orderNumber = await sendOrders(order)
                    setOrderNumber(orderNumber)
                } else {
                    setOrderNumber(((await submitCustomOrder(getFormValues(form))).orderNumber));
                }
            }
        }
    ];


    const steps = [...additionalStepsStart[type], ...standardSteps]

    // Google Login stuff
    const responseMessage = (response) => {
        console.log(response);
    };

    const errorMessage = (error) => {
        console.log(error);
    };

    function handleReset() {
        // Clear the cart
        clearCart();
        setAnnotations({ cart: getNumberOfProductsInCart() })

        // Navigate the user to a default page
        navigate("/store")

    }

    return <Stack>
        <StickyHeader label={type === "normal" ? "Shopping Cart" : "Your Custom Order"} />
        <form ref={form}>
            <Stepper steps={steps} defaults={{
                firstName: userProfile?.firstName, lastName: userProfile?.lastName, phoneNumber: userProfile?.phoneNumber, email: userProfile?.emailAddress,
                shippingAddress: userProfile?.shipping.address, shippingSuburb: userProfile?.shipping.suburb, shippingPostcode: userProfile?.shipping.postcode, shippingState: userProfile?.shipping.state, shippingbillingsame: "Different",
                billingAddress: userProfile?.billing.address, billingSuburb: userProfile?.billing.suburb, billingPostcode: userProfile?.billing.postcode, billingState: userProfile?.billing.state, trans_outputStyle: "Slip Yoke"
            }} />
        </form>
    </Stack>

}

export default ShoppingCart;