import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { useRef } from 'react';
import { updateOrder } from '../api/rest';
import { getFormValues } from '../util/forms';
import DefaultFormMap from '../components/DefaultFormMap';
import { stateEnums } from './Enums';

const BillingTab = ({ authorization, order, wrapAPIAction }) => {
    const form = useRef(null);
    const disabled = authorization == undefined || authorization.type != "administrator";

    const items = [
        { name: "billingAddress", label: 'Billing Address', defaultValue: order.orderInfo.billing.address, disabled },
        { name: "billingSuburb", label: 'Suburb', defaultValue: order.orderInfo.billing.suburb, disabled },
        { name: 'billingPostCode', label: "Postcode", defaultValue: order.orderInfo.billing.postcode, disabled },
        {
            name: 'billingState', label: "State", defaultValue: order.orderInfo.billing.state, disabled, options: stateEnums.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                    {option.value}
                </MenuItem>
            ))
        }
    ]
    return <Box >
        <DefaultFormMap form={form} items={items} />
        {!disabled && <Button sx={{ mt: 1 }} variant="outlined" onClick={() => wrapAPIAction(async()=>updateOrder({ authorization, orderId: order._id, formValues: getFormValues(form) }))}>Save Changes</Button>}
    </Box>
}

export default BillingTab