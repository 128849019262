import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import CardContent from '@mui/material/CardContent';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Avatar, ButtonBase, Card, CardActions, CardHeader, CardMedia, CircularProgress, Container, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { red } from '@mui/material/colors';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import Slider from '@mui/material/Slider';
import StickyHeader from '../components/stickyHeader';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { API_ENDPOINT } from '../Platforms';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFilterById, getFilterId, getVehicleEngine, getVehicleMakes, getVehicleModels, getVehicleSeries, getVehicleVariant } from '../Database/Vehicles/vehicleList';
import { useEffect } from 'react';
import axios from 'axios';


const keepOnly = (items, state) => {
    Object.keys(state).forEach(prop => {
        if (!items.includes(prop)) {
            delete state[prop]
        }
    })
}

const selectors = [
    {
        name: "make",
        funct: () => getVehicleMakes(),
        getRequires: () => true,
        cleanup: (state) => keepOnly(["make"], state)
    },
    {
        name: "model",
        funct: (state) => getVehicleModels(state.make),
        getRequires: (state) => state.make,
        cleanup: (state) => keepOnly(["make", "model"], state)
    },
    {
        name: "series",
        funct: (state) => getVehicleSeries(state.make, state.model),
        getRequires: (state) => state.make && state.model,
        cleanup: (state) => keepOnly(["make", "model", "series"], state)
    },
    {
        name: "engine",
        funct: (state) => getVehicleEngine(state.make, state.model, state.series),
        getRequires: (state) => state.make && state.model && state.series,
        cleanup: (state) => keepOnly(["make", "model", "series", "engine"], state)
    },
    {
        name: "variant",
        funct: (state) => getVehicleVariant(state.make, state.model, state.series, state.engine),
        getRequires: (state) => state.make && state.model && state.series && state.engine,
        cleanup: (state) => keepOnly(["make", "model", "series", "engine", "variant"], state)
    }
]

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function paramsToObject(entries) {
    const result = {}
    for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
}


function Store(props) {

    let query = useQuery();

    const [selectedValues, setSelectedValues] = useState({});
    const [products, setProducts] = useState([]);
    const [filterExpanded, setFilterExpanded] = useState(true)
    const [productList, setProductList] = useState([]);

    const navigate = useNavigate()

    useEffect(() => {
        // Do the API call here.
        axios.post("http://" + API_ENDPOINT + "/getproducts", "{}", {
            headers: {
                "content-type": "application/json"
            }
        }).then(res => {
            setProductList(res.data.products)
        })
    }, [])

    const card = (item) => (<Card variant='outlined' sx={{ height: "100%" }} >
        <Link to={"details/" + item._id + "/" + getFilterId(selectedValues)} style={{ color: "inherit", textDecoration: "none" }}>
            <CardMedia
                component="img"
                image={item.image[0]}
            />
            <CardContent>
                <Typography variant="h5" color="text.primary"><b>{item.name} </b></Typography>
                <Typography sx={{ mt: 2 }} variant="body2" color="text.secondary">{item.description} </Typography>
            </CardContent>
        </Link>
    </Card>
    )

    useEffect(() => {
        const params = paramsToObject(query)
        const vehicleDetails = getFilterById(params.id)
        if (params && vehicleDetails) {
            setSelectedValues(vehicleDetails)
            setProducts(filterProductList(vehicleDetails.make, vehicleDetails.model, vehicleDetails.series, vehicleDetails.engine, vehicleDetails.variant))
        } else {
            try {
                const previousCar = JSON.parse(window.localStorage.getItem("carConfig"))
                if (!previousCar) return;
                setSelectedValues(previousCar)
            } catch (err) { }
        }
    }, [productList])

    // Filter product list client side to make it run smoother - can offload to api at a later stage if needed.
    function filterProductList(make, model, series, engine, variant) {
        let filteredList = [];

        for (let i = 0; i < productList.length; i++) {
            let cp = productList[i];

            if (((cp.filters["make"] == undefined) || (cp.filters["make"].value == "")) || (!!make && (cp.filters["make"].value.indexOf(make) != -1))) {
                if (((cp.filters["model"] == undefined) || (cp.filters["model"].value == "")) || (!!model && (cp.filters["model"].value.indexOf(model) != -1))) {
                    if (((cp.filters["series"] == undefined) || (cp.filters["series"].value == "")) || (!!series && (cp.filters["series"].value.indexOf(series) != -1))) {
                        if (((cp.filters["engine"] == undefined) || (cp.filters["engine"].value == "")) || (!!engine && (cp.filters["engine"].value.indexOf(engine) != -1))) {
                            if (((cp.filters["variant"] == undefined) || (cp.filters["variant"].value == "")) || (!!variant && (cp.filters["variant"].value.indexOf(variant) != -1))) {
                                filteredList.push(cp);
                            }
                        }
                    }
                }
            }
        }
        return filteredList;
    }

    if (productList.length == 0)
        return <CircularProgress thickness={2} size={"5em"} />

    else return (<div style={{ position: "relative" }}>

        <StickyHeader label={"Our Products"} />

        <Typography variant='body' ml={1}>
            We have an extensive range of high performance products you can rely on in any situation
        </Typography>

        <Box mt={4} />
        <StickyHeader>
            <Accordion disableGutters expanded={filterExpanded} onChange={e => setFilterExpanded(!filterExpanded)} elevation={4} sx={{
                marginLeft: 0.5, marginRight: 0.5, borderRadius: 1, '&:before': {
                    display: 'none'
                }, flex: 1
            }} style={{}}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle2">My car details</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Grid container >
                        {selectors.map(selector => (
                            <Grid xs={12} sm={6} md={4} lg={3} sx={{ display: "flex" }}>
                                <Autocomplete
                                    variant="outlined"
                                    options={selector.funct(selectedValues)}
                                    renderInput={(params) => <TextField {...params}
                                        label={selector.name.toUpperCase()} />}
                                    onChange={(e) => {
                                        selector.cleanup(selectedValues)
                                        setSelectedValues({ ...selectedValues, ...{ [selector.name]: e.target.innerText } })
                                    }}
                                    value={selectedValues[selector.name] || ""}
                                    sx={{ flexGrow: 1, minWidth: 150, flexGrow: 1, mt: 1, mb: 1, ml: 1, mr: 1 }}
                                    item
                                    disabled={!selector.getRequires(selectedValues)}
                                >
                                </Autocomplete>
                            </Grid>
                        ))}
                    </Grid>
                    <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end", paddingRight: 7 }}>
                        <Button variant="outlined" onClick={() => {

                            if (Object.keys(selectedValues).length === 5) {
                                setFilterExpanded(false)
                                const id = {
                                    id: getFilterId(selectedValues)
                                }

                                window.localStorage.setItem("carConfig", JSON.stringify({
                                    make: selectedValues.make,
                                    model: selectedValues.model,
                                    series: selectedValues.series,
                                    engine: selectedValues.engine,
                                    variant: selectedValues.variant
                                }))

                                setProducts(filterProductList(selectedValues.make, selectedValues.model, selectedValues.series, selectedValues.engine, selectedValues.variant))
                                navigate('/store?' + new URLSearchParams(id).toString())
                            }
                        }} >Apply Filter</Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </StickyHeader>

        <Box mt={4} />

        <Grid container spacing={2} marginLeft={0} marginRight={0}>
            {
                products.map((product, i) =>
                    <Grid xs={12} sm={6} lg={4}>
                        {card(product)}
                    </Grid>
                )}
        </Grid>


    </div>)
}

export default Store;