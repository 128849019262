import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Container, IconButton, Stack, useTheme } from '@mui/material';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { fetchPlatformData } from '../Platforms';
import FilterIcon from '@mui/icons-material/FilterAlt';
import MenuIcon from '@mui/icons-material/Menu';

const fontWeight = 500
const StickyHeader = (props) => {
    const heading = useRef(null)
    const headingPlaceHolder = useRef(null)
    const [headingSticky, setHeadingSticky] = useState(false);
    const { stickyHeader } = fetchPlatformData("theme");

    const theme = useTheme()

    const stylingFixed = headingSticky ? { fontWeight, position: "fixed", top: 64, backgroundColor: theme.palette.background.default, backdropFilter: fetchPlatformData("theme").backdropFilter } : { fontWeight }
    const stylingPlaceHolder = headingSticky ? 48 : 0

    useEffect(() => {
        const onScroll = (e) => {
            if (headingPlaceHolder.current.getBoundingClientRect().top <= 64) return setHeadingSticky(true)
            return setHeadingSticky(false)
        }

        window.addEventListener("scroll", onScroll)

        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    return (
        <React.Fragment>
            {/* Only use the orange bar if we are rendering a lable */}
            {props.label && <Box style={{ height: 4, backgroundColor: stickyHeader, margin: -4.5, marginTop: -2, borderRadius: 0, marginBottom: 1 }} />}

            {/* Create height placeholder to offset when object is pinned */}
            <div ref={headingPlaceHolder} style={{ height: stylingPlaceHolder }} />

            <Container disableGutters maxWidth="xl" sx={{ ...{ display: "flex", zIndex: 2, position: "relative", ml: -0.5 }, ...stylingFixed }}>
                {props.label && <Typography ref={heading} variant='h5' color={stickyHeader} margin={0} paddingLeft={2} paddingBottom={1} paddingTop={1}  >
                    {props.label}
                </Typography>}
                {props.children}
            </Container>

            {(headingSticky && props.children ) && <div style={{marginTop: -48}}>
                {props.children}
            </div>}
        </React.Fragment>)
}

export default StickyHeader