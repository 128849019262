import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import StickyHeader from '../../components/stickyHeader';
import { API_ENDPOINT, fetchPlatformData } from '../../Platforms';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, IconButton, Snackbar, snackbarClasses } from '@mui/material';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getTimeStamp, wrapAPICall } from '../../Utilities';
import ProgressModal from '../../components/ProgressModal';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HeaderItem = (props) => {
    <TextField
        name={"Test"}
        label={"Test"}
        select
        variant="outlined"
        sx={{ flexGrow: 1, minWidth: 150, flexGrow: 1, mt: 1, mb: 1, ml: 1, mr: 1 }}
    // onChange={onChange}
    >

    </TextField>
    return <Grid xs={3} sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant='subtitle'>
            {props.header}
        </Typography>
        <Typography variant='subtitle2'>
            {props.text}
        </Typography>
    </Grid>
}

function ContactUsManagement(props) {
    const form = useRef(null);

    // Show only unread
    const [showOnlyUnread, setShowOnlyUnread] = useState(false);

    // Contact us messages
    const [contactUsMsgs, setContactUsMsgs] = useState(null);

    // API Spinner control
    // {state: true/false, refresh: true/false}
    const [isWaitingAPI, setWaitingAPI] = useState({ state: false, refresh: false });

    // Authorisation Status
    const [authorization, setAuthorization] = useState({ isAuthorized: false, username: "", password: "" });

    // SnackBar API invocation
    const [snackBarState, setSnackBarState] = useState({ alertSeverity: "", message: "", open: false });
    const snackBarAlertSeverityEnums = { error: "error", warning: "warning", info: "info", success: "success" };

    function displaySnackBar(alertSeverity, message) {
        setSnackBarState({ alertSeverity: alertSeverity, message: message, open: true })
    }

    const snackBarClose = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setSnackBarState({ alertSeverity: snackBarState.alertSeverity, message: snackBarState.message, open: false });
    };



    // Login Handler
    const logIn = async () => {
        const authInfo = {};

        if (!form.current) return;

        Object.keys(form.current.elements).forEach(element => {
            const thisElement = form.current.elements[element]
            if (thisElement.tagName === "INPUT") {
                if (!thisElement.value) return
                authInfo[thisElement.name] = thisElement.value
            }
        })

        // Validate input first
        if (authInfo.username == undefined) { displaySnackBar(snackBarAlertSeverityEnums.error, "Username Invalid"); return; }
        if (authInfo.password == undefined) { displaySnackBar(snackBarAlertSeverityEnums.error, "Password Invalid"); return; }

        // Send the belongs to if it is appended in the username
        // Syntax: username:belongsTo:id
        const splitArgs = authInfo.username.split(':');
        if (splitArgs.length == 3 && splitArgs[1] === 'belongsTo') {
            authInfo.username = splitArgs[0];
            authInfo._belongsTo = parseInt(splitArgs[2]);
        }

        // Attempt to log in
        setWaitingAPI({ state: true, refresh: false });
        const res = await wrapAPICall(axios.post("http://" + API_ENDPOINT + "/adminauth", JSON.stringify(authInfo), {
            headers: {
                "content-type": "application/json"
            }
        }))
        const auth = res.data.authorization;
        setAuthorization(auth);

        // Check if isAuthorized has been specified by the API.
        if (auth.isAuthorized)
            displaySnackBar(snackBarAlertSeverityEnums.success, "Login Success! - Welcome: " + auth.firstName + " " + auth.lastName);
        else displaySnackBar(snackBarAlertSeverityEnums.error, "Login Failed. Invalid Username / Password.");

        setWaitingAPI({ state: true, refresh: true });

    }

    async function getMessages() {
        setWaitingAPI({ state: true, refresh: false });
        const res = await wrapAPICall(axios.post("http://" + API_ENDPOINT + "/admingetcontactusmessages", JSON.stringify(authorization), {
            headers: {
                "content-type": "application/json"
            }
        }))

        let response = res.data;

        if (response.messages.length > 0) {
            // Sort so that newest messages are at the top (latest message id's)
            response.messages = response.messages.sort(function (a, b) { return b.time - a.time })
            if (showOnlyUnread) response.messages = response.messages.filter((obj) => obj.status === "new")
        }

        setContactUsMsgs(response.messages);

        // Display outcome
        if (response.status === 'success') {
            // displaySnackBar(snackBarAlertSeverityEnums.success, "Fetched Messages Successfully");
        }
        else displaySnackBar(snackBarAlertSeverityEnums.error, "Error getting messages from Database");

        setWaitingAPI({ state: false, refresh: false });
    }

    useEffect(() => {
        if (!authorization.isAuthorized) return;
        getMessages()
    }, [authorization])

    useEffect(() => {
        if (!isWaitingAPI.refresh) return;
        getMessages()
    }, [isWaitingAPI])

    // Marks the specificed message ID to read in the DB
    function setMessageStatus(id, read) {
        setWaitingAPI({ state: true, refresh: false });

        let request = structuredClone(authorization);
        request.msgid = id;                  // Set the message ID we want to alter
        request.status = read ? "read" : "new";            // Set the state.

        wrapAPICall(axios.post("http://" + API_ENDPOINT + "/admincontactusmessages", JSON.stringify(request), {
            headers: {
                "content-type": "application/json"
            }
        })).then(res => {
            const response = res.data;

            // Display outcome
            if (response.status === 'success') {
                //    displaySnackBar(snackBarAlertSeverityEnums.success, "Marked Message ID: " + id + " to " + request.status);
            }
            else displaySnackBar(snackBarAlertSeverityEnums.error, "Error!");

            setWaitingAPI({ state: true, refresh: true })
        })
    }

    // Marks the specified message ID to deleted in the DB
    function deleteMessage(id) {
        setWaitingAPI({ state: true, refresh: false });

        let request = structuredClone(authorization);
        request.msgid = id;                 // Set the message ID we want to alter
        request.deleteMessage = true;       // Set the state.

        wrapAPICall(axios.post("http://" + API_ENDPOINT + "/admincontactusmessages", JSON.stringify(request), {
            headers: {
                "content-type": "application/json"
            }
        })).then(res => {
            const response = res.data;

            // Display outcome
            if (response.status === 'success') {
                //  displaySnackBar(snackBarAlertSeverityEnums.success, "Deleted Message with ID: " + id);
            }
            else displaySnackBar(snackBarAlertSeverityEnums.error, "Error!");

            setWaitingAPI({ state: true, refresh: true });
        })
    }

    // User Logged in - show all the messages from the database
    if (authorization.isAuthorized && contactUsMsgs) return (<Box sx={{ position: "relative" }}>
        <ProgressModal display={(isWaitingAPI.state || (authorization.isAuthorized && !contactUsMsgs))} />

        <StickyHeader label={"Contact Us Management: " + authorization.firstName + " " + authorization.lastName + " @ " + authorization.organization} />

        <Box sx={{ mt: 1, mb: 2}} >
            <Button sx={{ mr: 2}} variant="outlined" onClick={getMessages}>Refresh</Button>
            <FormControlLabel control={<Checkbox />} label="Show Only UnRead" onChange={() => {
                setWaitingAPI({ state: true })
                setShowOnlyUnread(!showOnlyUnread)
                setWaitingAPI({ statue: false, refresh: true })
            }} checked={showOnlyUnread} />
        </Box>

        {contactUsMsgs.map(msg =>

            <Card variant='outlined' key={msg._id} sx={{ mb: 1 }}><Accordion disableGutters elevation={0} sx={{
                '.MuiAccordionDetails-root': {},
                '.MuiAccordionSummary-root': { minHeight: 0, height: "1em" },
                '&:before': {
                    display: 'none'
                }
            }} style={{}}>
                <AccordionSummary style={{ minHeight: 80, backgroundColor: msg.status == "new" ? "#e6e6ff" : "#FFFFFF" }} aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                    <Grid sx={{ display: "flex", width: "100%" }} container>
                        <HeaderItem header="From" text={msg.name} />
                        <HeaderItem header="Contact Info" text={msg.emailAddress + " -- " + msg.phoneNumber} />
                        <HeaderItem header="Subject" text={msg.subject} />
                        <HeaderItem header="Received" text={getTimeStamp(msg.time)} />
                    </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 2 }}>

                    <Typography variant='subtitle' > {msg.message} </Typography>

                    <Box>
                        <Button sx={{ mt: 2, mr: 1 }} variant="outlined" onClick={() => setMessageStatus(msg._id, msg.status === 'new')}>{"Mark " + (msg.status === 'new' ? "Read" : "UnRead")}</Button>
                        <Button sx={{ mt: 2 }} variant="outlined" onClick={() => deleteMessage(msg._id)}>Delete Message</Button>
                    </Box>

                </AccordionDetails>
            </Accordion></Card>)}

        <Snackbar
            open={snackBarState.open}
            autoHideDuration={5000}
            onClose={snackBarClose}>
            <Alert onClose={snackBarClose} severity={snackBarState.alertSeverity} sx={{ width: '100%' }}>{snackBarState.message}</Alert>
        </Snackbar>

    </Box>)


    // User Not logged in ... show the login form
    if (!authorization.isAuthorized) return (<Box>

        <StickyHeader label="Contact Us Management: Login" />

        <form style={{ display: "flex", flexDirection: "column" }} ref={form}>
            <TextField sx={{ mt: 1, mb: 3 }} id="outlined-basic" name="username" label="Username" variant="outlined" />
            <TextField sx={{ mb: 3 }} id="outlined-basic" name="password" label="Password" variant="outlined" />
        </form>
        <Button sx={{ mb: 8 }} variant="outlined" onClick={logIn}>Login</Button>

        <Snackbar
            open={snackBarState.open}
            autoHideDuration={5000}
            onClose={snackBarClose}>
            <Alert onClose={snackBarClose} severity={snackBarState.alertSeverity} sx={{ width: '100%' }}>{snackBarState.message}</Alert>
        </Snackbar>
    </Box>)
}

export default ContactUsManagement;