import * as React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { getTimeStamp } from '../Utilities';
import SendIcon from '@mui/icons-material/Send';
import { useEffect } from 'react';
import { useRef } from 'react';

const DefaultMessage = ({ messages, sendMessage, wrapAPIAction }) => {
    const [message, setMessage] = useState('')
    const theme = useTheme()
    const scrollBox = useRef(null);

    useEffect(() => {
        if (!scrollBox.current) return;
        scrollBox.current.scrollTo({
            left: 0,
            top: scrollBox.current.scrollHeight,
            behavior: 'smooth'// : 'auto'
        })
    }, [scrollBox, messages])

    return <div>
        <Box ref={scrollBox} sx={{ overflowY: "auto", maxHeight: 400, pr: 1 }}>
            {messages.map(message => {
                const isAdmin = message.user.toLowerCase().includes("mat")
                return <Box sx={{ textAlign: isAdmin ? "end" : "start", mb: 1 }}>
                    <div style={{ borderStyle: "solid", borderWidth: 1, borderColor: isAdmin ? theme.palette.primary.main : theme.palette.secondary.main, display: "inline-block", padding: 6, paddingRight: 12, paddingLeft: 12, paddingTop: 0, maxWidth: "80%", borderRadius: 12 }}>
                        <Typography variant='body' fontWeight={300} fontSize={"0.6em"}>{getTimeStamp(message.time)}</Typography>
                        <Typography variant='body' fontWeight={300} fontSize={"0.6em"}> {message.user}</Typography>

                        <Typography variant='subtitle2' fontSize="0.8em" fontWeight={300} mt={-0.5}> {message.msg}</Typography>
                    </div>
                    <br />
                </Box>
            }
            )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <TextField
                sx={{ flexGrow: 1, display: "flex" }}
                onChange={(e) => {
                    e.stopPropagation();
                    setMessage(e.target.value)
                }}
                name={"messageField"}
                label={"Message"}
                autoComplete="off"
                variant='standard'
                value={message}
            />

            <SendIcon sx={{ fontSize: "2em", ml: 2 }} color='primary' variant="outlined" onClick={async () => {
                await wrapAPIAction(async () => sendMessage(message))
                setMessage("")
            }} />

        </Box>
    </div>

}

export default DefaultMessage;