import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Avatar, ButtonBase, Card, CardActions, CardHeader, CardMedia, Container, Paper, Slide, useTheme } from '@mui/material';
import Store from './Pages/Store';
import { BrowserRouter, Routes, Route, useNavigate, Link, useLocation } from "react-router-dom";
import ContactUs from './Pages/ContactUs'
import Services from './Pages/Services'
import CustomOrder from './Pages/CustomOrder'
import Gallery from './Pages/ImageGallery'
import Background from './components/background';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProductDetails from './Pages/ProductDetails'
import ShoppingCart from './Pages/ShoppingCart';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchPlatformData } from './Platforms';
import { useEffect } from 'react';
import { useRef } from 'react';
import Checkout from './Pages/Checkout';
import MyAccount from './Pages/MyAccount';
import { getAggregatedProductsInCart, getNumberOfProductsInCart } from './Structures/Cart';
import OrderManagement from './Pages/Admin/OrderManagement';
import ProductManagement from './Pages/Admin/ProductManagement';
import ContactUsManagement from './Pages/Admin/ContactUsManagement';
import AdminMain from './Pages/Admin/AdminMain';
import CustomTailshaftInfo from './Pages/CustomTailshaftInfo';
import NotFound from './Pages/NotFound';
import Shop from './Pages/Shop';

const breakAt = "xl"
const drawerWidth = 240;

const { primary } = fetchPlatformData("theme");
const platformName = fetchPlatformData("name")

function DrawerAppBar(props) {

  // Set the title here
  document.title = platformName;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState("services")
  const [darkMode, setDarkMode] = React.useState(useMediaQuery('(prefers-color-scheme: dark)'));
  const [annotations, setAnnotations] = React.useState({ cart: getNumberOfProductsInCart() });

  const location = useLocation();
  const routeContent = useRef(null);
  const prefersDarkMode = darkMode;
  const navMenu = fetchPlatformData('navMenu');

  // Do runtime stuff for nav menu 
  for (let i = 0; i < navMenu.length; i++) {
    if (navMenu[i].label === 'Cart')
      navMenu[i].annotation = annotations.cart;
  }

  // Navigation Function check
  function isPathValid(path) {
    for (let i = 0; i < navMenu.length; i++) {
      if (navMenu[i].clickTarget.includes(path))
        return true;
    }
    return false;
  }

  const appTheme = React.useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1920,
          },
        },
        palette: {
          primary: {
            main: primary
          },
          mode: darkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );


  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
      </Typography>
      <List>
        {navMenu.map((item) => (
          <ListItem key={item.label} disablePadding onClick={() => navigateTo(item.clickTarget)}>
            <ListItemButton>
              <IconButton aria-label="settings">
                {item.icon}
              </IconButton>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const navigate = useNavigate();

  const navigateTo = (target) => {
    navigate(target)
    setCurrentTab(target)
  }

  useEffect(() => {
    if (routeContent.current && location.pathname !== "/") window.scrollTo({
      left: 0,
      top: routeContent.current.offsetTop - 64,
      behavior: 'smooth'// : 'auto'
    })//routeContent.current.scrollIntoView()
  }, [location])

  const width = "calc((100vw - 1932px)/2)"

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      {/* App bar */}
      <Slide in>
        <AppBar elevation={0} component="nav" sx={{ background: "none" }}>
          {/* Stop blur bleed */}
          <div style={{ height: "100vh", width, backgroundColor: appTheme.palette.background.default, position: "fixed" }} />
          <div style={{ height: "100vh", width, backgroundColor: appTheme.palette.background.default, position: "fixed", right: 0 }} />
          <Container maxWidth={breakAt}>

            <Toolbar sx={{
              backdropFilter: fetchPlatformData("theme").backdropFilter, backgroundColor: appTheme.palette.primary.main,
              marginLeft: -3, mr: -3, transform: "translate3d(0, 0, 0)", display: "flex"
            }}>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  display: { md: 'none' }, ml: { xs: 1 }
                }}
                color='#fff' >
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, order: 2 }} style={{}} />

              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', order: 2 } }} style={{}} />

              <Box sx={{ display: 'flex', flexDirection: "row", order: 2 }} style={{}}>
                {navMenu.map((item) => (
                  <Button variant={currentTab === item.clickTarget ? 'outlined' : 'none'} key={item.label} sx={{
                    fontWeight: currentTab === item.clickTarget ? 1000 : 300, padding: 1, borderRadius: 0, color: "#fff", display: {
                      xs: item.alwaysAppear ? "flex" : "none", md: "flex"
                    }, borderColor: currentTab === item.clickTarget ? "rgba(255,255,255,0.5)" : "none"
                  }} onClick={() => navigateTo(item.clickTarget)}>
                    <div style={{ position: "aboslute", height: "100%", display: "flex" }}>
                      {item.toolbarType === "icon" ? item.icon : item.label}
                      {item.annotation && <div style={{ position: "absolute", top: 0, right: 0, backgroundColor: appTheme.palette.background.paper, borderRadius: 64, height: "1.3em", width: '1.3em', display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography color="primary" fontSize={"0.8em"}>
                          {item.annotation}
                        </Typography>
                      </div>}
                    </div>
                  </Button>
                ))}
              </Box>

              <Box sx={{
                m: 0, p: 0, alignItems: "center", justifyContent: "center", order: {
                  xs: 4,
                  md: 1
                }
              }}>
                <img onClick={() => setDarkMode(!darkMode)} style={{ height: "64px", maxWidth: 128, objectFit: "contain", padding: 0, margin: 0 }} src={fetchPlatformData("siteImages").logo} />
              </Box>

            </Toolbar>
          </Container>
        </AppBar>
      </Slide>
      {/* Nav bar */}
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box' },
          }}
          anchor='bottom'
        >
          {drawer}
        </Drawer>
      </nav>

      {/* Content container */}
      <Container maxWidth={breakAt} disableGutters component="main">
        <div style={{ height: 74 }} />

        <Container maxWidth={breakAt} disableGutters >
          <Container maxWidth={breakAt} disableGutters sx={{ zIndex: -4, position: "relative" }}>
            <Paper elevation={0} sx={{ top: 0, position: "absolute", height: "100vh", zIndex: -2, width: "100%" }}></Paper>
          </Container>

          <Container maxWidth={breakAt} disableGutters sx={{ display: "grid" }}>


            <div style={{ minHeight: "75vh" }}>
              <Background />
            </div>

            {/* Actual routed page */}

            <Paper ref={routeContent} sx={{
              margin: 0, paddingLeft: 0.5, paddingRight: 0.5, minHeight: '100vh',
              borderRadius: 0, overflow: "hidden", paddingTop: "1px", border: "none"
            }} variant='outlined' >
              <Routes on>
                <Route exact path="/" element={<Services />} />
                <Route path="/contactus" element={isPathValid("contactus") ? <ContactUs /> : <NotFound />} />
                <Route path="/services" element={isPathValid("services") ? <Services /> : <NotFound />} />
                <Route path="/shop" element={isPathValid("shop") ? <Shop /> : <NotFound />} />
                <Route path="/gallery" element={isPathValid("gallery") ? <Gallery /> : <NotFound />} />
                <Route path="/customtailshaftinfo" element={isPathValid("customtailshaftinfo") ? <CustomTailshaftInfo /> : <NotFound />} />
                <Route path="/customorder" element={isPathValid("customorder") ? <CustomOrder /> : <NotFound />} />
                <Route path="/store/*" element={isPathValid("store") ? <Store /> : <NotFound />} />
                <Route path="/store/details/:pid/:fid" element={isPathValid("store") ? <ProductDetails annotations={annotations} setAnnotations={setAnnotations} /> : <NotFound />} />
                <Route path="/store/cart" element={isPathValid("store") ? <ShoppingCart annotations={annotations} setAnnotations={setAnnotations} type={"normal"} /> : <NotFound />} />
                <Route path="/store/checkout" element={isPathValid("store") ? <Checkout /> : <NotFound />} />
                <Route path="/store/account" element={isPathValid("store") ? <MyAccount /> : <NotFound />} />
                <Route path="/admin/main" element={isPathValid("admin") ? <AdminMain /> : <NotFound />} />
                <Route path="/admin/ordermanagement" element={isPathValid("admin") ? <OrderManagement /> : <NotFound />} />
                <Route path="/admin/productmanagement" element={isPathValid("admin") ? <ProductManagement /> : <NotFound />} />
                <Route path="/admin/contactusmanagement" element={isPathValid("admin") ? <ContactUsManagement /> : <NotFound />} />
                <Route path="*" element={<NotFound />} />
              </Routes>

            </Paper>
          </Container>
        </Container>
      </Container>
    </ThemeProvider>
  );
}

export default DrawerAppBar;