import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import StickyHeader from '../../components/stickyHeader';
import { API_ENDPOINT, fetchPlatformData } from '../../Platforms';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { CircularProgress, IconButton, Snackbar, snackbarClasses } from '@mui/material';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const items = [
  { link: "/admin/ordermanagement", label: "Orders & Custom Orders" },
  { link: "/admin/contactusmanagement", label: "Contact Messages" },
  { link: "/admin/productmanagement", label: "Product Management" }
]

function AdminMain(props) {

  const navigate = useNavigate()

  return <Box>
    <StickyHeader label="What would you like to manage?" />
    <Grid container spacing={2}>
      {items.map(item => <Grid sx={{ p: 4 }} xs={12} sm={6} lg={3}> <Button fullWidth sx={{ p: 4, height: "100%" }} variant="outlined" onClick={() => navigate(item.link)}>{item.label}</Button></Grid>)}
    </Grid>
  </Box>
};

export default AdminMain;