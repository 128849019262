import * as React from 'react';
import Card from '@mui/material/Card';
import { CardMedia, Box, Paper, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Masonry from '@mui/lab/Masonry';
import Modal from '@mui/material/Modal';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import StickyHeader from '../components/stickyHeader';
import { fetchPlatformData } from '../Platforms';


function hexToRgbA(hex, a) {
   var c;
   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
         c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${a})`;
   }
   throw new Error('Bad Hex');
}

hexToRgbA('#fbafff')


const ImageGallery = () => {

   const imageContent = fetchPlatformData("imageGallery");

   const [open, setOpen] = React.useState(false);

   const handleOpen = (path) => setOpen(path);
   const handleClose = () => setOpen(false);
   const theme = useTheme()

   return (
      <Box>
         {open && <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description" >

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", backdropFilter: "blur(10px)" }} onClick={() => handleClose()}>
               <div style={{ position: "relative" }}>

                  <img src={open.imgPath} style={{ objectFit: "contain", maxWidth: "100vw", maxHeight: "100vh", display: "block", paddingBottom: theme.spacing().replace("px", "") * 6 }} />
                  <Typography sx={{ position: "absolute", bottom: 0, left: 0, backdropFilter: "blur(10px)", width: "100%", padding: 2, backgroundColor: hexToRgbA(theme.palette.background.default, 0.5) }} margin={0} variant="body2" color="text.primary">{open.imgDescription}</Typography>
               </div>
            </div>
         </Modal>}

         <StickyHeader label="Our Images"/>

         <Masonry style={{ margin: 0 }} columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
            {imageContent.map((image, i) =>
               <Card onClick={() => handleOpen(image)}>
                  <CardMedia loading="lazy">
                     <img style={{ width: "100%", display: "block" }} src={image.imgPath} />
                  </CardMedia>
               </Card>

            )}
         </Masonry>
      </Box>
   )
};

export default ImageGallery;