import * as React from 'react';
import Box from '@mui/material/Box';
import StickyHeader from '../components/stickyHeader';
import { CreateFieldSet } from '../Structures/Stepper';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomTailshaftInfo = () => {
    const style = { width: "100%", height: 300, objectFit: "fill" }
    const style2 = { width: "100%" };

    const navigate = useNavigate()

 
    return (
        <Box>
            <StickyHeader label="Tailshaft 101" />
            <div style={{ display: "flex", alignItems: "stretch" }}>
                <div style={{ flex: 1 }}>
                    <CreateFieldSet step={
                        {
                            name: '',
                            paragraphs: [{
                                style: { display: "block" },
                                name: "Transmission",
                                type: "fields",
                                items: [
                                    { id: "transOutput", name: "Select Output Style", type: "dropDown", items: ["Slip Yoke", "3 Bolt Flange", "4 Bolt Flange", "6 Bolt Flange", "8 Bolt Flange", "Fixed Yoke"], trackState: true },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/transmission/output/slip-yoke.jpg", visibleIf: (state) => state.transOutput === "Slip Yoke", style },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/transmission/output/3-bolt-flange.jpg", visibleIf: (state) => state.transOutput === "3 Bolt Flange", style },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/transmission/output/4-bolt-flange.jpg", visibleIf: (state) => state.transOutput === "4 Bolt Flange", style },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/transmission/output/6-8-bolt-flange.jpg", visibleIf: (state) => state.transOutput === "6 Bolt Flange", style },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/transmission/output/6-8-bolt-flange.jpg", visibleIf: (state) => state.transOutput === "8 Bolt Flange", style },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/transmission/output/fixed-yoke.jpg", visibleIf: (state) => state.transOutput === "Fixed Yoke", style },
                                    { type: "custom", content: <div style={{ height: 100 }} /> },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/transmission/interface/3-bolt-flange.jpg", visibleIf: (state) => state.transOutput === "3 Bolt Flange", style: style2 },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/transmission/interface/4-bolt-flange.jpg", visibleIf: (state) => state.transOutput === "4 Bolt Flange", style: style2 },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/transmission/interface/6-bolt-flange.jpg", visibleIf: (state) => state.transOutput === "6 Bolt Flange", style: style2 },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/transmission/interface/8-bolt-flange.jpg", visibleIf: (state) => state.transOutput === "8 Bolt Flange", style: style2 },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/transmission/interface/fixed.jpg", visibleIf: (state) => state.transOutput === "Fixed Yoke", style: style2 }
                                ]
                            }]
                        }
                    } i={0} activeStep={0} defaults={{transOutput:"Slip Yoke"}} readOnly={false} />

                </div>

                <div style={{ flex: 1 }}>
                    <CreateFieldSet step={
                        {
                            name: '',
                            paragraphs: [{
                                style: { display: "block" },
                                name: "Tailshaft",
                                type: "fields",
                                items: [
                                    { id: "tailshaftStyle", name: "Select Style", type: "dropDown", items: ["1 Piece", "2 Piece", "3 Piece"], trackState: true },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/tailshaft/1-piece.jpg", visibleIf: (state) => state.tailshaftStyle === "1 Piece", style },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/tailshaft/2-piece.jpg", visibleIf: (state) => state.tailshaftStyle === "2 Piece", style },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/tailshaft/3-piece.jpg", visibleIf: (state) => state.tailshaftStyle === "3 Piece", style }
                                ]
                            }]
                        }
                    } i={0} activeStep={0} defaults={{tailshaftStyle:"2 Piece"}} readOnly={false} />
                </div>

                <div style={{ flex: 1 }}>
                    <CreateFieldSet step={
                        {
                            name: '',
                            paragraphs: [{
                                style: { display: "block" },
                                name: "Differential",
                                type: "fields",
                                items: [
                                    { id: "diffInput", name: "Select Input Style", type: "dropDown", items: ["Pinion Yoke", "3 Bolt Flange", "4 Bolt Flange", "6 Bolt Flange", "8 Bolt Flange"], trackState: true },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/differential/input/pinion-yoke.jpg", visibleIf: (state) => state.diffInput === "Pinion Yoke", style },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/differential/input/3-bolt-flange.jpg", visibleIf: (state) => state.diffInput === "3 Bolt Flange", style },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/differential/input/4-bolt-flange.jpg", visibleIf: (state) => state.diffInput === "4 Bolt Flange", style },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/differential/input/6-8-bolt-flange.jpg", visibleIf: (state) => state.diffInput === "6 Bolt Flange", style },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/differential/input/6-8-bolt-flange.jpg", visibleIf: (state) => state.diffInput === "8 Bolt Flange", style },
                                    { type: "custom", content: <div style={{ height: 100 }} /> },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/differential/interface/fixed.jpg", visibleIf: (state) => state.diffInput === "Pinion Yoke", style: style2 },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/differential/interface/3-bolt-flange.jpg", visibleIf: (state) => state.diffInput === "3 Bolt Flange", style: style2 },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/differential/interface/4-bolt-flange.jpg", visibleIf: (state) => state.diffInput === "4 Bolt Flange", style: style2 },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/differential/interface/6-bolt-flange.jpg", visibleIf: (state) => state.diffInput === "6 Bolt Flange", style: style2 },
                                    { name: "ImageName", type: "rawimage", imgsrc: "/custom_order/gj/tailshaft_101/differential/interface/8-bolt-flange.jpg", visibleIf: (state) => state.diffInput === "8 Bolt Flange", style: style2 }
                                ]
                            }]
                        }
                    } i={0} activeStep={0} defaults={{diffInput:"4 Bolt Flange"}} readOnly={false} />
                </div>
            </div>
            <Typography>Notes:</Typography>
            <Typography>1. All Measurements must be done with vehicle at ride height.</Typography>

            <Button variant="outlined" onClick={navigate("/customorder")}>Continue With Order</Button>
        </Box>
    )
};

export default CustomTailshaftInfo;