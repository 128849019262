import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import StickyHeader from '../components/stickyHeader';
import { fetchPlatformData } from '../Platforms';

const services = fetchPlatformData("services");

const Services = () => {
  return (
    <Box>
      <StickyHeader label="Our specialist services"/>
      <Grid container spacing={2}>
        {services.map((service, i) =>
          <Grid key={i} item xs={12} sm={6} md={4} lg={4} >
            <Card variant='outlined' sx={{ height: "100%" }}>
              <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%", justifyContent: "space-between", margin: 2 }}>
                <img
                  src={service.image}
                  alt=""
                  style={{
                    display: "block",
                    maxHeight: 180
                  }} />
                  
                <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column", height: "100%", margin: 1 }}>
                  <Typography sx={{ mt: 1 }} gutterBottom variant="subtitle1" component="div">
                    <b>{service.title}</b>
                  </Typography>

                  <Typography variant="body2" color="text.primary">
                    {service.summary}
                  </Typography>

                </Box>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  )
};

export default Services;