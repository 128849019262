import React from "react"
import { allCategories } from "../Database/Categories/Categories"



const iterate = (categories, currentNest, i) => {
    let type = "react"
    i++
    return Object.keys(categories).map(category => {

        const thisItem = categories[category]
        const newNest = []
        if (type === "program") currentNest.push({ item: category, members: newNest })
        if (type === "react") currentNest.push(<div style={{ marginLeft: i * 10 }}>{category}{newNest}</div>)
        if (typeof thisItem === "object") {
            return iterate(thisItem, newNest, i)
        } else {
            return category
        }
    })
}

export const getFromLevel = (path, index) => {

    let current = allCategories;

    path.forEach((pathway, i) => {
        if (i >= index) return;
        //const original = current
        current = current[pathway]
        //console.log(pathway, original, current, path)
    })

    const vals = Object.keys(current)
    return vals
}

export const CreateMenu = () => {
    return null
    let reactProp = []

    iterate(allCategories, reactProp, 0)
    console.log(reactProp)
    return reactProp
}
