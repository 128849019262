import * as React from 'react';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { green, red } from '@mui/material/colors';
import { fetchPlatformData } from '../Platforms';
import DoneIcon from '@mui/icons-material/DoneAll';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ProgressModal from './ProgressModal';


const OrderComplete = ({ orderNumber, handleReset }) => {
    return <React.Fragment>
        <ProgressModal display={!orderNumber} />
        <Box sx={{ display: "flex", justifyContent: "center" }}>

            {(orderNumber !== "ERR" && orderNumber) && <div style={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
                <Typography variant='h6' sx={{ mt: 4, mb: 1, p: 2 }}> {"Your Order #" + orderNumber + " has been placed"} </Typography>
                <Typography variant='body' sx={{ mb: 1, p: 2 }}> We will contact you shortly </Typography>
                <DoneIcon sx={{ color: green[500], fontSize: "5em" }} />
            </div>}

            {orderNumber === "ERR" && <div style={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
                <Typography variant='h6' sx={{ mt: 4, mb: 1, p: 2 }}> {"Whoops something went wrong!"} </Typography>
                <Typography variant='body' sx={{ mb: 1, p: 2 }}>Your order was NOT completed. Get in touch with us on: {fetchPlatformData('contactUs')[0].phone_number}</Typography>
                <ErrorOutlineOutlinedIcon sx={{ color: red[500], fontSize: "5em" }} />
            </div>}
        </Box>

        {/* OK Button */}
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>OK</Button>
        </Box>
    </React.Fragment>
}

export default OrderComplete;