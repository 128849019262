import * as React from 'react';
import Card from '@mui/material/Card';
import { Accordion, AccordionDetails, AccordionSummary, Chip, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFormattedPrice, getTimeStamp } from '../Utilities';

const getOrderColor = (status) => {
    const colorMap = {
        Rejected: "error",
        Pending: "info",
        "Painted - Ready For Freight": "info"
    }
    // primary - for primary interface elements.
    // secondary - for secondary interface elements.
    // error - for elements that the user should be made aware of.
    // warning - for potentially dangerous actions or important messages.
    // info - for highlighting neutral information.
    // success - for indicating the successful completion of an action that the user triggered.

    return colorMap[status] || "primary"
}

const OrderAccordion = ({ order, children, originator }) => {
    const summary = originator === "administrator" ? `${order.orderInfo.customer.firstName} ${order.orderInfo.customer.lastName} - ${order.type} order` : `${order.type} order`
    const theme = useTheme()

    return <Card variant='outlined' sx={{ mb: 1, borderColor: theme.palette[getOrderColor(order.status)].main, borderWidth: 2, mb: 2, mt: 2 }}><Accordion disableGutters elevation={0} sx={{
        '.MuiAccordionDetails-root': {},
        '.MuiAccordionSummary-root': { minHeight: 0, height: "1em" },
        '&:before': {
            display: 'none'
        }
    }} style={{}}>
        <AccordionSummary style={{ minHeight: 80 }} aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Grid container style={{ width: "100%", alignItems: "center", display: "flex" }}>
                <Grid xs={3} sm={3}>
                    <div>
                        <Typography variant='subtitle'>{"#" + order._id}</Typography>
                        <Typography variant='subtitle2'>{
                            new Date(order.date).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" })
                             // getTimeStamp(order.date)
                        }</Typography>
                    </div>
                </Grid>
                <Grid xs={3} sm={3}>
                    <Typography variant='body' fontWeight={300}> {summary} </Typography>
                </Grid>
                <Grid xs={3} sm={3} sx={{ display: "flex", justifyContent: "center" }}>
                    <Chip label={order.status} sx={{ marginLeft: 2, fontSize: "0.6em", padding: 0 }} color={getOrderColor(order.status)} variant="standard" />
                </Grid>
                <Grid xs={3} sm={3} sx={{ textAlign: "end" }}>
                    <Typography variant='body' sx={{ minWidth: 100 }}>{getFormattedPrice(order.subtotal + order.shipping)}</Typography>
                </Grid>
            </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 2, pb: 1 }}>
            {children}
        </AccordionDetails>
    </Accordion>
    </Card>
}

export default OrderAccordion 