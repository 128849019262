import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StickyHeader from '../../components/stickyHeader';
import { API_ENDPOINT, fetchPlatformData } from '../../Platforms';
import { useRef } from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import DefaultMessage from '../../components/DefaultMessage';
import DisplayOrder from '../../Structures/DisplayOrder';
import OrderAccordion from '../../Structures/OrderAccordion';
import TabSet from '../../components/Tabs';
import CustomerTab from '../../Structures/CustomerTab';
import StatusTab from '../../Structures/StatusTab';
import ShippingTab from '../../Structures/ShippingTab';
import BillingTab from '../../Structures/BillingTab';
import ProgressModal from '../../components/ProgressModal';
import { getOrders, login, sendMessageAdmin, sendStaffMessageAdmin } from '../../api/rest';
import ErrorModal from '../../components/ErrorModal';

function OrderManagement() {
  const form = useRef(null);

  const [isWaitingAPI, setIsWaitingAPI] = useState(false);
  const [authorization, setAuthorization] = useState({ isAuthorized: false, username: "", password: "" });
  const [orderHistory, setOrderHistory] = useState(null);
  const [error, setError] = useState(null);
  const [moduleRequestsReload, setModuleRequestsReload] = useState(null);

  let dataList = { ...fetchPlatformData("orderForm")[1], ...fetchPlatformData("orderForm")[2] };

  // Login Handler
  const logIn = async () => {
    setIsWaitingAPI(true)
    const authInfo = {};

    if (!form.current) return;

    Object.keys(form.current.elements).forEach(element => {
      const thisElement = form.current.elements[element]
      if (thisElement.tagName === "INPUT") {
        if (!thisElement.value) return
        authInfo[thisElement.name] = thisElement.value
      }
    })

    if (!authInfo.username || !authInfo.password) {
      setIsWaitingAPI(false)
      return setError("Please enter both a username and password")
    }

    const splitArgs = authInfo.username.split(':');
    if (splitArgs.length == 3 && splitArgs[1] === 'belongsTo') {
      authInfo.username = splitArgs[0];
      authInfo._belongsTo = parseInt(splitArgs[2]);
    }

    const res = await login({ username: authInfo.username, password: authInfo.password })
    setAuthorization(res)
    setIsWaitingAPI(false)
  }

  const wrapAPIAction = async (funct, orderId) => {
    setIsWaitingAPI(true)
    await funct()
    setIsWaitingAPI(false)
    setModuleRequestsReload(!moduleRequestsReload)
    
    if (orderId) setError("Successfully updated order " + orderId)
  }

  React.useEffect(() => {
    if (!authorization.isAuthorized) return;

    setIsWaitingAPI(true)
    getOrders({ authorization })
      .then(res => {
        setIsWaitingAPI(false)
        setOrderHistory(res.orders);
      })
  }, [authorization, moduleRequestsReload])

  return <Box sx={{ position: "relative" }}>
    <ProgressModal display={isWaitingAPI} />
    <ErrorModal setError={setError} error={error} />
    {!authorization.isAuthorized && <React.Fragment>
      <Box>
        <StickyHeader label="Order Management: Login" />

        <form style={{ display: "flex", flexDirection: "column" }} ref={form}>
          <TextField sx={{ mt: 1, mb: 3 }} id="outlined-basic" name="username" label="Username" variant="outlined" />
          <TextField sx={{ mb: 3 }} id="outlined-basic" name="password" label="Password" variant="outlined" />
        </form>
        <Button sx={{ mb: 8 }} variant="outlined" onClick={logIn}>Login</Button>
      </Box>
    </React.Fragment>}

    {authorization.isAuthorized && <Box>

      <StickyHeader label={"Order Management: " + authorization.firstName + " " + authorization.lastName + " @ " + authorization.organization} />

      {orderHistory && orderHistory.map((order, i) => <OrderAccordion order={order} originator={"administrator"}>

        <TabSet data={[{
          label: "Status",
          content: <StatusTab order={order} authorization={authorization} wrapAPIAction={(funct) => wrapAPIAction(funct, order._id)} />
        }, {
          label: "Messages",
          content: <DefaultMessage messages={order.messages}
            sendMessage={(value) => sendMessageAdmin({ authorization, id: order._id, value })} wrapAPIAction={(funct) => wrapAPIAction(funct)} />
        }, {
          label: "Staff Messages",
          content: <DefaultMessage messages={order.messagesInternal}
            sendMessage={(value) => sendStaffMessageAdmin({ authorization, id: order._id, value })} wrapAPIAction={(funct) => wrapAPIAction(funct)} />
        }, {
          label: "Customer",
          content: <CustomerTab order={order} authorization={authorization} wrapAPIAction={(funct) => wrapAPIAction(funct, order._id)} />
        }, {
          label: "Shipping",
          content: <ShippingTab order={order} authorization={authorization} wrapAPIAction={(funct) => wrapAPIAction(funct, order._id)} />
        }, {
          label: "Billing",
          content: <BillingTab order={order} authorization={authorization} wrapAPIAction={(funct) => wrapAPIAction(funct, order._id)} />
        }, {
          label: "Order",
          content: <DisplayOrder order={order} dataList={dataList} authorization={authorization} wrapAPIAction={(funct) => wrapAPIAction(funct, order._id)} />
        }]} />
      </OrderAccordion>)}
    </Box>
    }
  </Box>
};

export default OrderManagement;