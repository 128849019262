import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

const DefaultFormMap = ({ items, form }) => {
    return <form ref={form}>
        <Grid container spacing={1} >
            {items.map(item =>
                <Grid xs={6} md={4} sx={{ display: "flex" }}>
                    <TextField
                        sx={{ flexGrow: 1, minWidth: 200, mb: 1, mt: 1, }}
                        onChange={(e) => {
                            e.stopPropagation();
                        }}
                        name={item.name}
                        label={item.label}
                        defaultValue={item.defaultValue}
                        disabled={item.disabled}
                        variant="outlined"
                        autoComplete="off"
                        select={!!item.options}
                    >
                        {item.options}
                    </TextField>
                </Grid>
            )}
        </Grid>
    </form>
}

export default DefaultFormMap;