import { Grid, Typography } from '@mui/material';
import * as React from 'react';

const NotFound = () => {

    return (
        <Grid   container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center">

            <Typography variant="h1">404</Typography>
            <Typography variant="subtitle1">The page you're looking for is not available</Typography>
        </Grid>
    )
};

export default NotFound;